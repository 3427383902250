import React, { useEffect, useState, useCallback } from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import axios from "axios";
import Chip from "@mui/material/Chip";
import TableChartOutlinedIcon from "@mui/icons-material/TableChartOutlined";
import { useTheme } from "@mui/material/styles";
import {
  Avatar,
  CardContent,
  LinearProgress,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
  linearProgressClasses,
} from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import "react-toastify/dist/ReactToastify.css";
import { useDropzone } from "react-dropzone";
import { ToastContainer, toast } from "react-toastify";
import { styled } from "@mui/material/styles";
import { useNavigate, useParams } from "react-router-dom";
import { InputLabel } from "@mui/material";
import { Button, Link } from "../../node_modules/@mui/material/index";
import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import Divider from "@mui/material/Divider";
import { MenuItem, Select } from "@mui/material";
import { Input } from "antd";
import ImageDropzone from "../pages/ImageDropzone";
import Paper from "@mui/material/Paper";
import { useDispatch, useSelector } from "react-redux";
import AntDesignSideBar from "../components/AntDesignSideBar";
import Grid from "@mui/material/Grid";
import { Container } from "@mui/system";
const drawerWidth = 240;

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: "#f5f5f5",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  color: theme.palette.text.secondary,
}));
function AdminDash(props) {
  const theme = useTheme();
  const CardStyle = styled(Card)(({ theme }) => ({
    background: "#498dd6",
    marginBottom: "22px",
    overflow: "hidden",
    position: "relative",
    "&:after": {
      content: '""',
      position: "absolute",
      width: "157px",
      height: "157px",
      background: "#2a70bb",
      borderRadius: "50%",
      top: "-105px",
      right: "-96px",
    },
  }));
  const params = useParams();

  const navigate = useNavigate();
  const commonStyles = {
    bgcolor: "background.paper",
    m: 1,
  };

  return (
    <div>
      <Box sx={{ display: "flex" }}>
        <Box
          component="nav"
          sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
          aria-label="mailbox folders"
        >
          {/* The implementation can be swapped with js to avoid SEO duplication of links. */}

          <AntDesignSideBar />
        </Box>
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            p: 3,
            width: { sm: `calc(100% - ${drawerWidth}px)` },
          }}
        >
          <Typography variant="h4" style={{ color: "grey" }}>
            Click To See Applications
          </Typography>
          <Divider />
          <Grid container spacing={2}>
            <Grid item sm={4} style={{ marginTop: "20px" }}>
              <Link href={"/fix-and-flip"}>
                <CardStyle>
                  <CardContent sx={{ p: 2 }}>
                    <List sx={{ p: 0, m: 0 }}>
                      <ListItem
                        alignItems="flex-start"
                        disableGutters
                        sx={{ p: 0 }}
                      >
                        <ListItemAvatar sx={{ mt: 0 }}>
                          <Avatar variant="rounded">
                            <TableChartOutlinedIcon fontSize="inherit" />
                          </Avatar>
                        </ListItemAvatar>
                        <ListItemText
                          sx={{ mt: 0 }}
                          primary={
                            <Typography variant="h5" sx={{ color: "white" }}>
                              Fix and Flip
                            </Typography>
                          }
                        />
                      </ListItem>
                    </List>
                  </CardContent>
                </CardStyle>
              </Link>
            </Grid>
            <Grid item sm={4} style={{ marginTop: "20px" }}>
              <Link href={"/groundUpAnalysis"}>
                <CardStyle>
                  <CardContent sx={{ p: 2 }}>
                    <List sx={{ p: 0, m: 0 }}>
                      <ListItem
                        alignItems="flex-start"
                        disableGutters
                        sx={{ p: 0 }}
                      >
                        <ListItemAvatar sx={{ mt: 0 }}>
                          <Avatar variant="rounded">
                            <TableChartOutlinedIcon fontSize="inherit" />
                          </Avatar>
                        </ListItemAvatar>
                        <ListItemText
                          sx={{ mt: 0 }}
                          primary={
                            <Typography variant="h5" sx={{ color: "white" }}>
                              Ground Up
                            </Typography>
                          }
                        />
                      </ListItem>
                    </List>
                  </CardContent>
                </CardStyle>
              </Link>
            </Grid>
            <Grid item sm={4} style={{ marginTop: "20px" }}>
              <Link href={"/multi-family-analysis"}>
                <CardStyle>
                  <CardContent sx={{ p: 2 }}>
                    <List sx={{ p: 0, m: 0 }}>
                      <ListItem
                        alignItems="flex-start"
                        disableGutters
                        sx={{ p: 0 }}
                      >
                        <ListItemAvatar sx={{ mt: 0 }}>
                          <Avatar variant="rounded">
                            <TableChartOutlinedIcon fontSize="inherit" />
                          </Avatar>
                        </ListItemAvatar>
                        <ListItemText
                          sx={{ mt: 0 }}
                          primary={
                            <Typography variant="h5" sx={{ color: "white" }}>
                              Multifamily Bridge
                            </Typography>
                          }
                        />
                      </ListItem>
                    </List>
                  </CardContent>
                </CardStyle>
              </Link>
            </Grid>
            <Grid item sm={4} style={{ marginTop: "20px" }}>
              <Link href={"/stabalized-bridge-analysis"}>
                <CardStyle>
                  <CardContent sx={{ p: 2 }}>
                    <List sx={{ p: 0, m: 0 }}>
                      <ListItem
                        alignItems="flex-start"
                        disableGutters
                        sx={{ p: 0 }}
                      >
                        <ListItemAvatar sx={{ mt: 0 }}>
                          <Avatar variant="rounded">
                            <TableChartOutlinedIcon fontSize="inherit" />
                          </Avatar>
                        </ListItemAvatar>
                        <ListItemText
                          sx={{ mt: 0 }}
                          primary={
                            <Typography variant="h5" sx={{ color: "white" }}>
                              Stabalized Bridge
                            </Typography>
                          }
                        />
                      </ListItem>
                    </List>
                  </CardContent>
                </CardStyle>
              </Link>
            </Grid>
            <Grid item sm={4} style={{ marginTop: "20px" }}>
              <Link href={"/rental-analysis"}>
                <CardStyle>
                  <CardContent sx={{ p: 2 }}>
                    <List sx={{ p: 0, m: 0 }}>
                      <ListItem
                        alignItems="flex-start"
                        disableGutters
                        sx={{ p: 0 }}
                      >
                        <ListItemAvatar sx={{ mt: 0 }}>
                          <Avatar variant="rounded">
                            <TableChartOutlinedIcon fontSize="inherit" />
                          </Avatar>
                        </ListItemAvatar>
                        <ListItemText
                          sx={{ mt: 0 }}
                          primary={
                            <Typography variant="h5" sx={{ color: "white" }}>
                              Rental Portfolios
                            </Typography>
                          }
                        />
                      </ListItem>
                    </List>
                  </CardContent>
                </CardStyle>
              </Link>
            </Grid>
            <Grid item sm={4} style={{ marginTop: "20px" }}>
              <Link href={"/single-property-analysis"}>
                <CardStyle>
                  <CardContent sx={{ p: 2 }}>
                    <List sx={{ p: 0, m: 0 }}>
                      <ListItem
                        alignItems="flex-start"
                        disableGutters
                        sx={{ p: 0 }}
                      >
                        <ListItemAvatar sx={{ mt: 0 }}>
                          <Avatar variant="rounded">
                            <TableChartOutlinedIcon fontSize="inherit" />
                          </Avatar>
                        </ListItemAvatar>
                        <ListItemText
                          sx={{ mt: 0 }}
                          primary={
                            <Typography variant="h5" sx={{ color: "white" }}>
                              Single Property
                            </Typography>
                          }
                        />
                      </ListItem>
                    </List>
                  </CardContent>
                </CardStyle>
              </Link>
            </Grid>
            <Grid item sm={4} style={{ marginTop: "20px" }}>
              <Link href={"/project99-analysis"}>
                <CardStyle>
                  <CardContent sx={{ p: 2 }}>
                    <List sx={{ p: 0, m: 0 }}>
                      <ListItem
                        alignItems="flex-start"
                        disableGutters
                        sx={{ p: 0 }}
                      >
                        <ListItemAvatar sx={{ mt: 0 }}>
                          <Avatar variant="rounded">
                            <TableChartOutlinedIcon fontSize="inherit" />
                          </Avatar>
                        </ListItemAvatar>
                        <ListItemText
                          sx={{ mt: 0 }}
                          primary={
                            <Typography variant="h5" sx={{ color: "white" }}>
                              Project 99
                            </Typography>
                          }
                        />
                      </ListItem>
                    </List>
                  </CardContent>
                </CardStyle>
              </Link>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </div>
  );
}

AdminDash.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

export default AdminDash;
