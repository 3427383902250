import React, { useState } from "react";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Container from "../../screens/Container";
import Typography from "@mui/material/Typography";
import CheckoutSteps from "../CheckoutSteps";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import Box from "@mui/material/Box";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import dayjs from "dayjs";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import Autocomplete from "@mui/material/Autocomplete";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import FormLabel from "@mui/material/FormLabel";
import InputAdornment from "@mui/material/InputAdornment";
import Checkbox from "@mui/material/Checkbox";
function RenovationDetails({ formData, setFormData, fieldErrors }) {
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;
  const top100Films = [
    { title: "Cleanout" },
    { title: "Light and cosmetic" },
    { title: "Gut Rehab" },
    { title: "Moderate rehab" },
    { title: "New Construction" },
    { title: "No Renovation planned" },
  ];
  const fixedOptions = [top100Films[5]];
  const [selectedOption, setSelectedOption] = useState("no");
  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };
  const [investedCapital, setInvestedCapital] = useState("");
  const [completedCapex, setCompletedCapex] = useState("");

  const totalBudget = parseFloat(investedCapital) + parseFloat(completedCapex);

  const handleInvestedCapitalChange = (event) => {
    setInvestedCapital(event.target.value);
    setFormData({ ...formData, investedCapital: investedCapital });
  };

  const handleCompletedCapexChange = (event) => {
    setCompletedCapex(event.target.value);
    setFormData({ ...formData, completedCapex: completedCapex });
  };
  return (
    <div>
      <CheckoutSteps step1 step2 step3 step4></CheckoutSteps>

      <Container>
        <Typography variant="h4" color="black" gutterBottom>
          Renovation details
        </Typography>
        {/* 
        <Typography variant="subtitle1" color="grey" gutterBottom>
          Purchase information
        </Typography>
        */}
        <Grid container spacing={2}>
          <Grid item sm={6}>
            <label style={{ fontSize: 15, fontWeight: 100, color: "grey" }}>
              What is the renovation budget including renovation capital that
              has already been invested plus the amount required to complete the
              project?
            </label>
            <TextField
              variant="outlined"
              InputProps={{
                startAdornment: "$",
              }}
              style={{ backgroundColor: "white" }}
              value={investedCapital}
              error={fieldErrors.investedCapital}
              onChange={handleInvestedCapitalChange}
              fullWidth
            />
          </Grid>
          <Grid item sm={6}>
            <label style={{ fontSize: 15, fontWeight: 100, color: "grey" }}>
              How much has been invested in completed capex to date
            </label>
            <TextField
              variant="outlined"
              InputProps={{
                startAdornment: "$",
              }}
              style={{ marginTop: 22, backgroundColor: "white" }}
              value={completedCapex}
              error={fieldErrors.completedCapex}
              onChange={handleCompletedCapexChange}
              fullWidth
            />
          </Grid>

          <Grid item sm={6}>
            <Box mt={2}>
              <Typography variant="subtitle1" style={{ color: "grey" }}>
                Total Budget: $
                {isNaN(totalBudget) ? "0.00" : totalBudget.toFixed(2)}
              </Typography>
            </Box>
          </Grid>
          <Grid item sm={6}>
            <Typography type="p" color="grey">
              Please describe the renovation *
            </Typography>
            <Autocomplete
              multiple
              id="checkboxes-tags-demo"
              options={top100Films}
              disableCloseOnSelect
              getOptionLabel={(option) => option.title}
              renderOption={(props, option, { selected }) => (
                <li {...props}>
                  <Checkbox
                    icon={icon}
                    checkedIcon={checkedIcon}
                    style={{
                      marginRight: 8,
                    }}
                    checked={selected}
                  />
                  {option.title}
                </li>
              )}
              style={{ width: 500 }}
              renderInput={(params) => (
                <TextField
                  style={{ backgroundColor: "white" }}
                  value={formData.renovationDescript || ""}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      renovationDescript: e.target.value,
                    })
                  }
                  {...params}
                  error={fieldErrors.renovationDescript}
                  label="Please describe the renovation *"
                  placeholder="Please describe the renovation *"
                />
              )}
            />
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}

export default RenovationDetails;
