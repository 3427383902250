import { Typography } from "@mui/material";
import React from "react";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Container from "../screens/Container";
import Grid from "@mui/material/Grid";
import review1 from "../Images/review1.jpg";
function ReviewsConstruction() {
  const theme = useTheme();
  return (
    <Box
      style={{ backgroundColor: "#F5F5F4" }}
      display={"flex"}
      flexDirection={{ xs: "column", md: "row" }}
      position={"relative"}
    >
      <Box
        width={1}
        order={{ xs: 2, md: 1 }}
        display={"flex"}
        alignItems={"center"}
      >
        <Container>
          <Box>
            <Typography
              variant="h4"
              align={"center"}
              gutterBottom
              sx={{
                color: "black",
                fontWeight: 300,
                marginTop: theme.spacing(1),
              }}
            >
              Reviews From Construction Business Owners
            </Typography>
            <Grid container spacing={2}>
              <Grid item sm={6}>
                <img src={review1} width="100%" />
              </Grid>
              <Grid item sm={6}>
                <img src={review1} width="100%" />
              </Grid>
            </Grid>
          </Box>
        </Container>
      </Box>
    </Box>
  );
}

export default ReviewsConstruction;
