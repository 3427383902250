import React from "react";
import ExcelFile from "react-data-export";

function ExcelExportButton({ data }) {
  const dataSet = [
    {
      columns: [
        {
          title: "Additional Property Info",
          style: { font: { sz: "12", bold: true } },
          width: { wpx: 100 },
        },
        {
          title: "Address City",
          style: { font: { sz: "12", bold: true } },
          width: { wpx: 80 },
        },
        {
          title: "Address State",
          style: { font: { sz: "12", bold: true } },
          width: { wpx: 80 },
        },
        {
          title: "Address Zip",
          style: { font: { sz: "12", bold: true } },
          width: { wpx: 80 },
        },
        {
          title: "After Repair Value",
          style: { font: { sz: "12", bold: true } },
          width: { wpx: 100 },
        },
        {
          title: "Arms Length Description",
          style: { font: { sz: "12", bold: true } },
          width: { wpx: 100 },
        },
        {
          title: "Authorized Sign",
          style: { font: { sz: "12", bold: true } },
          width: { wpx: 80 },
        },
        {
          title: "Authorized Signatory",
          style: { font: { sz: "12", bold: true } },
          width: { wpx: 100 },
        },
        {
          title: "Best Terms",
          style: { font: { sz: "12", bold: true } },
          width: { wpx: 80 },
        },
        {
          title: "Birth Date",
          style: { font: { sz: "12", bold: true } },
          width: { wpx: 80 },
        },
        {
          title: "Birth Month",
          style: { font: { sz: "12", bold: true } },
          width: { wpx: 80 },
        },
        {
          title: "Birth Year",
          style: { font: { sz: "12", bold: true } },
          width: { wpx: 80 },
        },
        {
          title: "Borrower Cell",
          style: { font: { sz: "12", bold: true } },
          width: { wpx: 80 },
        },
        {
          title: "Borrower Citizenship",
          style: { font: { sz: "12", bold: true } },
          width: { wpx: 100 },
        },
        {
          title: "Borrower Email",
          style: { font: { sz: "12", bold: true } },
          width: { wpx: 100 },
        },
        {
          title: "Borrower Last",
          style: { font: { sz: "12", bold: true } },
          width: { wpx: 80 },
        },
        {
          title: "Borrower Social Security",
          style: { font: { sz: "12", bold: true } },
          width: { wpx: 100 },
        },
        {
          title: "Borrowing Entity Information",
          style: { font: { sz: "12", bold: true } },
          width: { wpx: 120 },
        },
        {
          title: "Borrowing Entity Owned",
          style: { font: { sz: "12", bold: true } },
          width: { wpx: 100 },
        },
        {
          title: "Broker Points",
          style: { font: { sz: "12", bold: true } },
          width: { wpx: 80 },
        },
        {
          title: "Closing Date",
          style: { font: { sz: "12", bold: true } },
          width: { wpx: 100 },
        },
        {
          title: "Construction Budget Percentage",
          style: { font: { sz: "12", bold: true } },
          width: { wpx: 140 },
        },
        {
          title: "Construction Holdback",
          style: { font: { sz: "12", bold: true } },
          width: { wpx: 120 },
        },
        {
          title: "Contact Last Name",
          style: { font: { sz: "12", bold: true } },
          width: { wpx: 120 },
        },
        {
          title: "Credit Score",
          style: { font: { sz: "12", bold: true } },
          width: { wpx: 100 },
        },
        {
          title: "Date of Incorporation",
          style: { font: { sz: "12", bold: true } },
          width: { wpx: 140 },
        },
        {
          title: "Entity Address",
          style: { font: { sz: "12", bold: true } },
          width: { wpx: 120 },
        },
        {
          title: "Entity Name",
          style: { font: { sz: "12", bold: true } },
          width: { wpx: 120 },
        },
        {
          title: "Entity Type",
          style: { font: { sz: "12", bold: true } },
          width: { wpx: 100 },
        },
        {
          title: "Exit Strategy",
          style: { font: { sz: "12", bold: true } },
          width: { wpx: 100 },
        },
        {
          title: "First Name",
          style: { font: { sz: "12", bold: true } },
          width: { wpx: 80 },
        },
        {
          title: "Guarantee Loan",
          style: { font: { sz: "12", bold: true } },
          width: { wpx: 100 },
        },
        {
          title: "Home Address",
          style: { font: { sz: "12", bold: true } },
          width: { wpx: 120 },
        },
        {
          title: "Initial Loan Amount",
          style: { font: { sz: "12", bold: true } },
          width: { wpx: 120 },
        },
        {
          title: "Insurance Company",
          style: { font: { sz: "12", bold: true } },
          width: { wpx: 120 },
        },
        {
          title: "Interest Rate",
          style: { font: { sz: "12", bold: true } },
          width: { wpx: 100 },
        },
        {
          title: "Interest Term",
          style: { font: { sz: "12", bold: true } },
          width: { wpx: 100 },
        },
        {
          title: "Interest Method",
          style: { font: { sz: "12", bold: true } },
          width: { wpx: 120 },
        },
        {
          title: "Is Condominium",
          style: { font: { sz: "12", bold: true } },
          width: { wpx: 120 },
        },
        {
          title: "Percentage or Amount",
          style: { font: { sz: "12", bold: true } },
          width: { wpx: 140 },
        },
        {
          title: "Percentage or Amount 2",
          style: { font: { sz: "12", bold: true } },
          width: { wpx: 140 },
        },
        {
          title: "Personally Guaranteeing",
          style: { font: { sz: "12", bold: true } },
          width: { wpx: 140 },
        },
        {
          title: "Points",
          style: { font: { sz: "12", bold: true } },
          width: { wpx: 80 },
        },
        {
          title: "Preferred Closing Attorney",
          style: { font: { sz: "12", bold: true } },
          width: { wpx: 160 },
        },
        {
          title: "Property Purchase Price",
          style: { font: { sz: "12", bold: true } },
          width: { wpx: 140 },
        },
        {
          title: "Property Source",
          style: { font: { sz: "12", bold: true } },
          width: { wpx: 120 },
        },
        {
          title: "Property Type",
          style: { font: { sz: "12", bold: true } },
          width: { wpx: 120 },
        },
        {
          title: "Purchase Amount",
          style: { font: { sz: "12", bold: true } },
          width: { wpx: 120 },
        },
        {
          title: "Refinance",
          style: { font: { sz: "12", bold: true } },
          width: { wpx: 100 },
        },
        {
          title: "Renovation Budget",
          style: { font: { sz: "12", bold: true } },
          width: { wpx: 140 },
        },
        {
          title: "Social Security",
          style: { font: { sz: "12", bold: true } },
          width: { wpx: 120 },
        },
        {
          title: "Term Months",
          style: { font: { sz: "12", bold: true } },
          width: { wpx: 120 },
        },
        {
          title: "Title Company",
          style: { font: { sz: "12", bold: true } },
          width: { wpx: 120 },
        },
        {
          title: "Transaction Armslength",
          style: { font: { sz: "12", bold: true } },
          width: { wpx: 160 },
        },
        {
          title: "Wholesaler Pay",
          style: { font: { sz: "12", bold: true } },
          width: { wpx: 120 },
        },
        {
          title: "Wholesaler Source",
          style: { font: { sz: "12", bold: true } },
          width: { wpx: 120 },
        },
      ],
      data: data.map((form) => [
        form.additionalPropertyInfo,
        form.addressCity,
        form.addressState,
        form.addressZip,
        form.afterRepairValue,
        form.armsLengthDescription,
        form.authorizedSign,
        form.authorizedSignatory,
        form.bestTerms,
        form.birthDate,
        form.birthMonth,
        form.birthYear,
        form.borrowerCell,
        form.borrowerCitizenship,
        form.borrowerEmail,
        form.borrowerLast,
        form.borrowerSocialSecurity,
        form.borrowingEntityInformation,
        form.borrowingEntityOwned,
        form.brokerPoints,
        form.closingDate,
        form.constructionBudgetPercentage,
        form.constructionHoldback,
        form.contactLastName,
        form.creditScore,
        form.dateofIncorp,
        form.entityAddress,
        form.entityName,
        form.entityType,
        form.exitStrategry,
        form.firstName,
        form.guranteeLoan,
        form.homeAddress,
        form.initialLoanAmount,
        form.insuranceCompany,
        form.interestRate,
        form.interestTerm,
        form.intrestMethod,
        form.isCondominium,
        form.percentageOrAmount,
        form.percentageOrAmount2,
        form.personallyGuranteeing,
        form.points,
        form.preferredClosingAttorney,
        form.propertyPurchasePrice,
        form.propertySource,
        form.propertyType,
        form.purchaseAmount,
        form.refinance,
        form.renovationBudget,
        form.socialSecurity,
        form.termMonths,
        form.titleCompany,
        form.transactionArmslength,
        form.wholesalerPay,
        form.wholesalerSource,
      ]),
    },
  ];

  return (
    <ExcelFile element={<button>Export to Excel</button>}>
      <ExcelFile.Sheet data={dataSet} name="Data">
        {dataSet[0].columns.map((column) => (
          <ExcelFile.Column
            key={column.title}
            label={column.title}
            value={column.title}
          />
        ))}
      </ExcelFile.Sheet>
    </ExcelFile>
  );
}

export default ExcelExportButton;
