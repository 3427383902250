import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "../screens/Container";
import Footer2 from "../components/Footer2";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
function TermsofUse() {
  return (
    <div style={{ backgroundColor: "#c0dced", marginBototm: 20 }}>
      <Container>
        <Typography variant="h3" color="black">
          Terms of Use
        </Typography>
        <Typography variant="body1" color="black">
          <br></br>
          <br></br>
          These Terms of Use shall become effective as of March 1, 2022.
          <br></br>
          <br></br>
          <strong>Introduction</strong>
          <br></br>
          <br></br>
          This is a legal agreement between you and Capital Velocity, LLC., a
          Nevada corporation (“Capital Velocity”), which may be contacted at
          6565 Spencer St., Suite 207, Las Vegas, NV 89119. By accessing this
          website, and any sub-sites of this website (together the “Site”),
          and/or using any of the Services (as defined below) accessible though
          the Site, you become a user and agree to, and are bound by, the terms
          and conditions of this agreement and the Capital Velocity Privacy
          Policy (collectively, “Terms”) for as long as you continue to use the
          Site or Services. IF YOU DO NOT AGREE TO BE BOUND BY THE TERMS, DO NOT
          USE THE SITE OR THE SERVICES. Your use of, or participation in,
          certain Services may be subject to additional terms, and such terms
          will be either listed in the Terms or will be presented to you for
          your acceptance when you sign up to use such Services or purchase such
          products. Please check these Terms periodically for changes because we
          reserve the right, in our sole discretion, to change, modify, add or
          remove portions of these Terms. Your continued use of the Site
          following the posting of changes will mean that you accept and agree
          to the changes. Please see the beginning of the Terms to determine
          when the Terms were last revised.
          <br></br>
          <br></br>
          As used in these Terms, the following words shall have the following
          meanings. “Capital Velocity” includes “Capital Velocity, LLC.”,
          “Capital Velocity”, “we”, “us” and variations such as “our”. “Service”
          includes the products, services and software that you order, receive
          or access as part of your use of Capital Velocity’s Sites.
          <br></br>
          <br></br>
          These terms state that any disputes between you and Capital Velocity
          must be resolved in binding arbitration, that you waive the right to
          participate in any class action, and that the law of the State of
          Nevada applies to all interactions between you and Capital Velocity.
          you may opt out of the arbitration and the class action waiver by
          following the procedures in section 10. g below
          <br></br>
          <br></br>
          <strong>1. Description of Services</strong>
          <br></br>
          <br></br>
          Capital Velocity engages in several business services (the “Services”)
          for which a customer may use the Site. Capital Velocity reserves the
          right to cancel a portion of any of our Services at any time.
          <br></br>
          <br></br>
          <ol type="a">
            <li>
              <strong>Marketplace Services</strong> <br></br>
              <br></br>
              First, Capital Velocity matches qualified small business owners
              with active banks, credit unions, and other lending sources (the
              “Marketplace Services”). Through proprietary matching technology,
              Capital Velocity helps business owners to identify the business
              loan category and specific lender(s) that offer the best
              opportunity for that business owner to prepare for and acquire a
              business loan. Once prepared, small business owners are then
              introduced to the most appropriate
            </li>
            <li>
              <strong>Other miscellaneous services</strong> <br></br>
              <br></br>
              Capital Velocity may add additional products and services over
              time, and these Terms of Use shall apply to those additional
              services.
            </li>
          </ol>
          <br></br>
          <br></br>
          <strong>2. Use of Site and Service:</strong>
          <br></br>
          <br></br>
          As a user of the Site or a user registered to use any of the Services
          (a “Registered User”), you agree to the following:
          <br></br>
          <br></br>
          <ol type="a">
            <li>
              Exclusive Use. Your account is to be used only by you and only for
              your business needs. You may not authorize others to use your
              account, and you may not assign or otherwise transfer your account
              to any other person or entity. You acknowledge that Capital
              Velocity is not responsible for third-party access to your account
              that results from theft or misappropriation of your user name and
              passwords.
            </li>
            <li>
              Information Submitted. You are solely responsible for, and assume
              all liability regarding (i) the information and content you
              provide through your use of the Site and any Services, (ii) the
              information and content you make available in any manner through
              the service, and (iii) your interaction with any and all
              third-parties. Any attempt to enter information to create a
              duplicate account will be rejected and your account will be put on
              hold.
            </li>
            <li>
              Risk Assumption and Precautions. You assume all risk when using
              the Services, including but not limited to all of the risks
              associated with any interactions with third-parties, including but
              not limited to any lenders to which you are matched, referred or
              provided.
            </li>
            <li>
              No Guarantees. Capital Velocity may not be able to provide matches
              for every individual or business seeking to use the Services.
              Capital Velocity makes no guarantees as to the number of matches
              or the suitability of the business funding for any individual or
              business which utilizes the Services.
            </li>
            <li>
              No False Information. You agree to provide accurate, true, current
              and complete information. In the event that Capital Velocity
              determines that the information that is provided is incomplete,
              fraudulent, false, or otherwise inaccurate, Capital Velocity
              reserves the right to terminate your access to the Services.
            </li>
            <li>
              Legal Purpose. You agree to not use the Site or Services for any
              illegal purpose. You will only use the Site or Service in
              accordance with federal, state and local laws.
            </li>
            <li>
              Business Purpose. You agree to only use the Site or Services for a
              bona fide business purpose. You agree not to use the Site or
              Services for personal, family or household purposes. You may not
              use the Site or Services to obtain information about or make
              decisions about anyone but yourself and/or your business.
            </li>
          </ol>
          <br></br>
          <br></br>
          <strong>3. Intellectual Property Rights:</strong>
          <br></br>
          <br></br>
          <ol type="a">
            <li>
              Ownership of Proprietary Information. You hereby acknowledge and
              agree that Capital Velocity is the owner of all rights in and to
              the Site and Services. Federal, state, and local laws and
              regulations protect these proprietary rights. You are permitted to
              use the Site and Services only as expressly authorized by these
              Terms. You may not copy, reproduce, distribute or create
              derivative works, reverse engineer or reverse compile any of the
              Site or Services or technology.
            </li>
            <li>
              No Use of Confidential Information. You will not post, copy,
              modify, transmit, disclose, show in public, create any derivative
              works from, distribute, make commercial use of, or reproduce in
              any way any (i) confidential information or (ii) other copyrighted
              material, trademarks, or other proprietary information accessible
              via the Site or Services, without first obtaining the prior
              written consent of the owner of such proprietary rights.
            </li>
            <li>
              License to Provided Content. By providing information or content
              to any account or public area of the Site or Service, you
              automatically grant, and you represent and warrant that you have
              the right to grant, to Capital Velocity and its users, an
              irrevocable, perpetual, non-exclusive, fully-paid, worldwide
              license to use, reproduce, publicly perform, publicly display and
              distribute such information and content, and to prepare derivative
              works of, or incorporate into other works, such information and
              content, and to grant and authorize sub-licenses of the foregoing.
              From time to time, we may create, test or implement new features
              or services on the Site in which you may voluntarily choose to
              participate, in accordance with the additional terms and
              conditions of such features or programs. By your voluntary
              participation in such features or programs, you grant us the
              rights stated in this subsection in connection with the additional
              terms and conditions (if any) of such features or services.
            </li>
          </ol>
          <br></br>
          <br></br>
          <strong>
            4. Links to Third Party Websites and Dealings with Advertisers and
            Sponsors:
          </strong>
          <br></br>
          <br></br>
          The Site and Services may contain links to websites of third-parties,
          including without limitation, advertisers, which are not under the
          control of Capital Velocity, and Capital Velocity is not responsible
          for the content of any linked site or any link contained in a linked
          site, or any changes or updates to such websites. Capital Velocity
          provides these links to you as a convenience, and the inclusion of any
          link does not imply that Capital Velocity endorses or accepts any
          responsibility for the content on such third-party websites. Your
          correspondence or business dealings with, or participation in
          promotions of, advertisers or third-parties found on or through the
          Site or Services are solely between you and such advertiser or
          third-party. You agree that Capital Velocity will not be responsible
          or liable for any loss or damage of any sort incurred as the result of
          the presence of such advertisers on the Site or Services. Please visit
          our Privacy Policy to learn more about how we use your information.
          <br></br>
          <br></br>
          <strong>5. Disclaimer of Warranty:</strong>
          <br></br>
          <br></br>
          No Warranties. This section will apply to the maximum extent permitted
          by applicable law. Capital Velocity provides the services on an “as
          is” and “as available” basis and grants no warranties of any kind,
          whether express, implied, statutory or otherwise with respect to the
          services or the site (including all information contained therein),
          including any implied warranties of merchantability, fitness for a
          particular purpose or non- infringement. Capital Velocity does not
          warrant that your use of the services will be secure, uninterrupted,
          always available, error-free or will meet your requirements, or that
          any defects in the services will be corrected. capital velocity
          disclaims liability for, and no warranty is made with respect to, the
          connectivity and availability of the services.
          <br></br>
          <br></br>
          Third party content. Third-Party Content. Opinions, advice,
          statements, offers, or other information or content made available
          through the Site or Services, but not directly by Capital Velocity,
          are those of their respective authors, and should not necessarily be
          relied upon. Such authors are solely responsible for such content.
          <br></br>
          <br></br>
          Capital Velocity does not: (i) guarantee the accuracy, completeness or
          usefulness of any information provided on the services, or (ii) adopt,
          endorse or accept responsibility for the accuracy or reliability of
          any opinion, advice, or statement made by any party other than Capital
          Velocity. Under no circumstances will Capital Velocity be responsible
          for any loss or damage resulting from anyone’s reliance on information
          or other content posted on the site or services, or transmitted to or
          by any users.
          <br></br>
          <br></br>
          Beta Features. From time to time, Capital Velocity may offer new
          “beta” features or tools with which its users may experiment on the
          Site or Services. Such features or tools are offered solely for
          experimental purposes and without any warranty of any kind, and may be
          modified or discontinued at Capital Velocity’s sole discretion. The
          provisions of this Disclaimer of Warranty section apply with full
          force to such features or tools.
          <br></br>
          <br></br>
          <strong>6. Limitation of Liability:</strong>
          <br></br>
          <br></br>
          Incidental Damages and Aggregate Liability. In no event will Capital
          Velocity be liable for any incidental, special, consequential or
          indirect damages arising out of or relating to the use or inability to
          use the services, including, without limitation, damages for loss or
          corruption of data or programs, service interruptions and procurement
          of substitute services, even if Capital Velocity knows or has been
          advised of the possibility of such damages. under no circumstances
          will Capital Velocity’s aggregate liability, in any form of action
          whatsoever in connection with this terms or the use of the services or
          site, exceed the price paid by you for your account or subscription,
          or, if you have not paid Capital Velocity for the use of any services,
          the amount of usd $25.00 or its equivalent.
          <br></br>
          <br></br>
          No Liability for non-Capital Velocity Actions. To the maximum extent
          permitted by law, in no event will Capital Velocity be liable for any
          damages whatsoever, whether direct, indirect, general, special,
          compensatory, consequential, and/or incidental, arising out of or
          relating to the conduct of you or anyone else in connection with the
          use of the services. Information Verification. Capital Velocity and
          its contractors may use various methods of verifying information that
          users have provided. However, none of those ways are perfect, and you
          agree that Capital Velocity and its contractors will have no liability
          to you arising from any incorrectly verified information.
          <br></br>
          <br></br>
          <strong>7. Indemnification:</strong>
          <br></br>
          <br></br>
          You agree to indemnify, defend and hold harmless Capital Velocity, its
          officers, directors, employees, agents and third parties, for any
          losses, costs, liabilities and expenses (including reasonable
          attorneys’ fees) relating to or arising out of (i) your use of or
          inability to use the Site or Services, (ii) any user postings made by
          you, (iii) your violation of any terms of these Terms or your
          violation of any rights of a third-party, or (iv) your violation of
          any applicable laws, rules or regulations. Capital Velocity reserves
          the right, at its own cost, to assume the exclusive defense and
          control of any matter otherwise subject to indemnification by you, in
          which event you will fully cooperate with Capital Velocity in
          asserting any available defenses.
          <br></br>
          <br></br>
          <strong>8. Dispute Resolution by Binding Arbitration:</strong>
          <br></br>
          <br></br>
          In the interest of resolving disputes between you and Capital Velocity
          in the most expedient and cost-effective manner, you and Capital
          Velocity agree that either party, at any time, may opt to adjudicate
          their disputes through binding arbitration instead of in courts of
          general jurisdiction (“Agreement to Arbitrate”). Arbitration is more
          informal than a lawsuit in court. Arbitration uses a neutral
          arbitrator instead of a judge or jury, condenses discovery, and is
          subject to very limited review by courts. Arbitrators can award the
          same damages and relief that a court can award. Any arbitration under
          these Terms will take place on an individual basis; class arbitrations
          and class actions are not permitted. You acknowledge and agree that
          the arbitrator may award relief (including monetary, injunctive, and
          declaratory relief) only in favor of the individual party seeking
          relief and only to the extent necessary to provide relief necessitated
          by that individual party’s claim(s). Any relief awarded cannot affect
          other users.
          <br></br>
          <br></br>
          You further agree and understand that any and all disputes, whether
          heard before a court of competent jurisdiction, or an arbitrator,
          shall be done solely on an individual basis, and that you
          affirmatively waive your right to participate in a class action to
          resolve any dispute (as defined below) that you may have with Capital
          Velocity.
          <br></br>
          <br></br>
          You understand that by entering into this agreement, you and Capital
          Velocity are each waiving the right to a trial by jury or to
          participate in a class action:
          <br></br>
          <br></br>
          <ol type="a">
            <li>
              Claims To Be Resolved By Binding Arbitration. You and Capital
              Velocity agree to arbitrate all disputes and claims between us.
              This agreement to arbitrate is intended to be broadly interpreted.
              It includes, but is not limited to: claims arising out of or
              relating to any aspect of the relationship between us, whether
              based in contract, tort, statute, fraud, misrepresentation, or any
              other legal theory; claims that arose before this or any prior
              Terms; claims that are currently the subject of purported class
              action litigation in which you are not a member of a certified
              class; and claims that may arise after the termination of these
              Terms.
            </li>
            <li>
              Exceptions. Notwithstanding Section 10(a), you and Capital
              Velocity agree that no statement herein shall be deemed to waive,
              preclude, or otherwise limit either party’s right to (i) pursue
              enforcement actions through applicable federal, state, or local
              agencies where such actions are available; (ii) seek injunctive
              relief in any competent court of law; or (iii) to file suit in a
              court of law to address intellectual property infringement claims.
            </li>
            <li>
              Arbitrator. Any arbitration between you and Capital Velocity will
              be governed by the Commercial Dispute Resolution Procedures
              (collectively, “AAA Rules”) of the American Arbitration
              Association (“AAA”), as modified by these Terms, and will be
              administered by the AAA. The AAA Rules and filing forms are
              available online at www.adr.org, by calling the AAA at
              1-800-778-7879.
            </li>
            <li>
              Notice and Process. A party who intends to seek arbitration must
              first send to the other, by certified mail, a written Notice of
              Dispute (“Notice”). The Notice to Capital Velocity should be
              addressed to: Capital Velocity, LLC., 6565 Spencer St. Suite 207,
              Las Vegas, NV 89119 (“Notice Address”). The Notice must (a)
              describe the nature and basis of the claim or dispute; and (b) set
              forth the specific relief sought (“Demand”). If you and Capital
              Velocity do not reach an agreement to resolve the claim within 30
              days after the Notice is received, you or Capital Velocity may
              commence an arbitration proceeding.
            </li>
            <li>
              Fees. In the event that you commence arbitration in accordance
              with these Terms, Capital Velocity will, at your request,
              reimburse you for your payment of the arbitration filing fee,
              unless your claim is for greater than $10,000, in which case the
              payment of any fees shall be decided by the AAA Rules. Any request
              for payment of fees by Capital Velocity should be submitted by
              mail to the AAA along with your Demand for Arbitration and Capital
              Velocity will make arrangements to pay all necessary fees directly
              to the AAA. In the event the arbitrator determines the claim(s)
              you assert in the arbitration to be frivolous or brought for an
              improper purpose (as measured by the standards set forth in
              Federal Rule of Civil Procedure 11(b)), you agree to reimburse
              Capital Velocity for all fees associated with the arbitration paid
              by Capital Velocity on your behalf that you otherwise would be
              obligated to pay under the AAA’s rules.
            </li>
            <br></br>
            <br></br>
            If your claim is for $10,000 or less, you may choose whether the
            arbitration will be conducted solely on the basis of documents
            submitted to the arbitrator, through a non-appearance based
            telephonic hearing, or by an in-person hearing as established by the
            AAA Rules.
            <br></br>
            <br></br>
            Any in-person arbitration hearings will take place at a location to
            be agreed upon in Las Vegas, Nevada. Regardless of the manner in
            which the arbitration is conducted, the arbitrator shall issue a
            reasoned written decision sufficient to explain the essential
            findings and conclusions on which the decision and award, if any,
            are based. The arbitrator may make rulings and resolve disputes as
            to the payment and reimbursement of fees or expenses at any time
            during the proceeding and upon request from either party made within
            14 days of the arbitrator’s ruling on the merits.
            <br></br>
            <br></br>
            <li>
              Claims To Be Resolved By Binding Arbitration. You and Capital
              Velocity agree to arbitrate all disputes and claims between us.
              This agreement to arbitrate is intended to be broadly interpreted.
              It includes, but is not limited to: claims arising out of or
              relating to any aspect of the relationship between us, whether
              based in contract, tort, statute, fraud, misrepresentation, or any
              other legal theory; claims that arose before this or any prior
              Terms; claims that are currently the subject of purported class
              action litigation in which you are not a member of a certified
              class; and claims that may arise after the termination of these
              Terms.
            </li>
            <li>
              Exceptions. Notwithstanding Section 10(a), you and Capital
              Velocity agree that no statement herein shall be deemed to waive,
              preclude, or otherwise limit either party’s right to (i) pursue
              enforcement actions through applicable federal, state, or local
              agencies where such actions are available; (ii) seek injunctive
              relief in any competent court of law; or (iii) to file suit in a
              court of law to address intellectual property infringement claims.
            </li>
            <li>
              Arbitrator. Any arbitration between you and Capital Velocity will
              be governed by the Commercial Dispute Resolution Procedures
              (collectively, “AAA Rules”) of the American Arbitration
              Association (“AAA”), as modified by these Terms, and will be
              administered by the AAA. The AAA Rules and filing forms are
              available online at www.adr.org, by calling the AAA at
              1-800-778-7879.
            </li>
            <li>
              Notice and Process. A party who intends to seek arbitration must
              first send to the other, by certified mail, a written Notice of
              Dispute (“Notice”). The Notice to Capital Velocity should be
              addressed to: Capital Velocity, LLC., 6565 Spencer St. Suite 207,
              Las Vegas, NV 89119 (“Notice Address”). The Notice must (a)
              describe the nature and basis of the claim or dispute; and (b) set
              forth the specific relief sought (“Demand”). If you and Capital
              Velocity do not reach an agreement to resolve the claim within 30
              days after the Notice is received, you or Capital Velocity may
              commence an arbitration proceeding.
            </li>
            <li>
              Fees. In the event that you commence arbitration in accordance
              with these Terms, Capital Velocity will, at your request,
              reimburse you for your payment of the arbitration filing fee,
              unless your claim is for greater than $10,000, in which case the
              payment of any fees shall be decided by the AAA Rules. Any request
              for payment of fees by Capital Velocity should be submitted by
              mail to the AAA along with your Demand for Arbitration and Capital
              Velocity will make arrangements to pay all necessary fees directly
              to the AAA. In the event the arbitrator determines the claim(s)
              you assert in the arbitration to be frivolous or brought for an
              improper purpose (as measured by the standards set forth in
              Federal Rule of Civil Procedure 11(b)), you agree to reimburse
              Capital Velocity for all fees associated with the arbitration paid
              by Capital Velocity on your behalf that you otherwise would be
              obligated to pay under the AAA’s rules.
            </li>
          </ol>
          <br></br>
          <br></br>
          <strong>
            9. Telephone communications and agreement to be contacted:
          </strong>
          <br></br>
          <br></br>
          Call Recording and Monitoring. You acknowledge that telephone calls to
          or from Capital Velocity, together with its agents and affiliates, may
          be monitored and recorded and you agree to such monitoring and
          recording.
          <br></br>
          <br></br>
          Providing Telephone Numbers and Other Contact Information. You verify
          that any contact information provided to Capital Velocity, including,
          but not limited to, your name, mailing address, email address, your
          residential or business telephone number, and/or your mobile telephone
          number, is true and accurate. You verify that you are the current
          subscriber or owner of any telephone number that you provide. You are
          strictly prohibited from providing a phone number that is not your
          own. If we discover that any information provided in connection with
          your registration is false or inaccurate, we may suspend or terminate
          your account at any time. Should any of your contact information
          change, including ownership of your telephone numbers, you agree to
          immediately notify us before the change goes into effect by replying
          STOP to any text message you receive from us, by notifying us in
          writing via email to info@capitalvelocity.com.
          <br></br>
          <br></br>
          Your Consent to Receive Automated Calls/Texts. You acknowledge that by
          voluntarily providing your telephone number(s), you expressly agree to
          receive artificial voices, prerecorded voice messages, and/or
          autodialed calls and text messages (such as SMS, MMS, or successor
          protocols or technologies) from Capital Velocity, and third-parties
          acting on its behalf, related to promotions, your account, any
          application or transaction, and/or your relationship with Capital
          Velocity. You acknowledge that automated calls or text messages may be
          made to your telephone number(s) even if your telephone number(s) is
          registered on any state or federal Do Not Call list. You agree that
          Capital Velocity may obtain, and you expressly agree to be contacted
          at, any email addresses, mailing addresses, or phone numbers provided
          by you or your representative at any time or obtained through other
          lawful means, such as skip tracing, caller ID capture, or other means.
          You agree to receive automated calls and text messages from Capital
          Velocity, even if you cancel your account or terminate your
          relationship with us, except if you opt- out (see below). You
          understand that you do not have to agree to receive automated
          promotional calls/texts as a condition of purchasing any goods or
          services or account creation. If you do not consent, you may email to
          info@capitalvelocity.com to further inquire about our products and
          services. To opt-out, please see the Opt-Out Instructions below.
          <br></br>
          <br></br>
          Opt-Out Instructions. Your consent to receive automated calls and
          texts is completely voluntary. You may opt-out at any time. To opt-out
          of text messages, reply STOP to any text message you receive from us.
          You may also text HELP for help. You acknowledge and agree to accept a
          final text message confirming your opt-out. We may use different
          shortcodes for different messaging purposes, so texting STOP to one
          shortcode will not effectuate a stop request for all shortcodes to
          which you are subscribed. If you would like to stop messages from
          multiple shortcodes, reply STOP to each shortcode to which you would
          like to unsubscribe. Alternatively, you may info@capitalvelocity.com
          advising that you want to opt out of text messages and specifying the
          phone number(s) for which that opt-out should apply To opt-out of
          automated voice calls (not text messages), you must (i) provide us
          with written notice revoking your consent to receiving automated
          calls; (ii) in that written notice, you must include your full name,
          mailing address, account number, and the specific phone number(s) for
          which you wish to stop automated calls; and (iii) send this written
          notice to info@capitalvelocity.com. It is your sole responsibility to
          notify us if you no longer want to receive automated calls or text
          messages. You waive any rights to bring claims for unauthorized or
          undesired calls or text messages by failing to opt-out immediately or
          by failing to follow these instructions. Please allow up to thirty
          (30) days to process any opt-out request. Please note that if you opt
          out of automated calls or text messages, we reserve the right to make
          non-automated calls to you. It is possible that third parties may have
          your contact information and you may continue to receive
          communications from these third parties despite an opt out request. We
          are not responsible for unwanted contact from third parties. Please
          contact third parties directly to inform them of your communication
          preferences.
          <br></br>
          <br></br>
          Fees and Charges. There is no fee to receive automated telephone calls
          or text messages from Capital Velocity. However, you may incur a
          charge for these calls or text messages from your telephone carrier,
          which is your sole responsibility. Message and data rates may apply.
          Check your telephone plan and contact your carrier for details. You
          represent and warrant that you are authorized to incur such charges
          and acknowledge that Capital Velocity is not responsible for such
          charges.
          <br></br>
          <br></br>
          Unauthorized Use of Your Telephone Device. You must notify Capital
          Velocity immediately of any breach of security or unauthorized use of
          your telephone device. Although Capital Velocity will not be liable
          for losses caused by any unauthorized use of your telephone device,
          you may be liable for our losses due to such unauthorized use.
          <br></br>
          <br></br>
          Your Indemnification to Us. You agree to indemnify Capital Velocity
          for any privacy, tort or other claims, including claims under the
          Federal Telephone Consumer Protection Act or its state law equivalent,
          including claims relating to your voluntary provision of a telephone
          number that is not owned by you and/or your failure to notify us of
          any changes in your contact information, including telephone number.
          You agree to indemnify, defend and hold us harmless from and against
          any and all such claims, losses, liabilities, costs and expenses
          (including reasonable attorneys’ fees). We shall have the exclusive
          right to choose counsel, at your expense, to defend any such claims.
          <br></br>
          <br></br>
          Release of Claims. In consideration of the services provided by
          Capital Velocity, you hereby release Capital Velocity from any and all
          claims, causes of action, lawsuits, injuries, damages, losses,
          liabilities or other harms resulting from or relating to telephone
          calls or text messages, including without limitation any claims,
          causes of action, or lawsuits based on any alleged violations of the
          law (including, without limitation, the Telephone Consumer Protection
          Act, Truth in Caller ID Act, Telemarketing Sales Rule, Fair Debt
          Collection Practices Act, or any similar state and local acts or
          statutes, and any federal or state tort or consumer protection laws).
          <br></br>
          <br></br>
          General. You are responsible for obtaining and maintaining all
          telephone devices and other equipment and software, and all internet
          service providers, mobile service, and other services needed to
          receive calls and text messages. Text messaging may only be available
          with select carriers with compatible handsets. Your obligations under
          this Section will survive termination of these Terms of Service.
          <br></br>
          <br></br>
          Text message Terms and Conditions:
          <br></br>
          <br></br>
          By “Opting In” to or using a Capital Velocity Text Message Service
          (“SMS Service”), you accept these Terms and Conditions and agree to
          resolve disputes with Capital Velocity through binding arbitration
          (and with very limited exceptions, not in court), and you waive any
          right to participate in class actions, all as detailed in Section 10
          entitled “Dispute Resolution by Binding Arbitration; Class Action
          Waivers” section Capital Velocity’s Terms of Use.
          <ol type="lower-alpha">
            <li>
              By opting In to Capital Velocity’s Text Message Service, you
              authorize and agree to allow Capital Velocity to use an autodialer
              or non-autodialer technology to send text messages to the cell
              phone number associated with your Opt-In (i.e., the number listed
              on the Opt-In form or instructions, or, if none, the number from
              which you send the Opt-In, or, if none, the number on file for the
              account associated with your Opt-In). You authorize Capital
              Velocity to include marketing content in any such messages. You do
              not have to Opt-In or agree to Opt-In as a condition of purchase
              or service. You confirm that you are the subscriber to the
              relevant phone number or that you are the customary user of that
              number on a family or business plan and that you are authorized to
              Opt-In. After Opting-In, in addition to the main messages the
              service offers, you may receive one or more welcome messages or
              administrative messages, such as (in some cases) a request to
              confirm your Opt-In.
            </li>
            <li>
              You can cancel the SMS service at any time. Just text “STOP” to
              the short code. After you send the SMS message “STOP” to us, we
              will send you an SMS message to confirm that you have been
              unsubscribed. After this, you will no longer receive SMS messages
              from us. If you want to join again, just sign up as you did the
              first time, and we will start sending SMS messages to you again.
            </li>
            <li>
              If you are experiencing issues with the messaging program, you can
              get help directly at info@capitalvelocity.com.
            </li>
            <li>
              Carriers are not liable for delayed or undelivered messages.
            </li>
            <li>
              As always, message and data rates may apply for any messages sent
              to you from us and to us from you. You will receive up to 15
              messages/month. If you have any questions about your text plan or
              data plan, it is best to contact your wireless provider.
            </li>
            <li>
              If you have any questions regarding privacy, please read our
              privacy policy:{" "}
              <a href="https://capitalvelocity.com/privacy-policy/">
                https://capitalvelocity.com/privacy-policy/
              </a>
            </li>
          </ol>
          <br></br>
          <br></br>
          <strong>10. General Provisions:</strong>
          <br></br>
          <br></br>
          Controlling Law and Jurisdiction. You agree that Nevada law (without
          giving effect to its conflicts of law principles) will govern these
          Terms, the Site and the Services and that any dispute arising out of
          or relating to these Terms, the Site or the Services will be subject
          to the exclusive jurisdiction and venue of the federal and state
          courts in Clark County, Nevada. You acknowledge and agree that any
          violation of these Terms may cause Capital Velocity irreparable harm,
          and therefore agree that Capital Velocity will be entitled to seek
          extraordinary relief in court, including but not limited to temporary
          restraining orders, preliminary injunctions and permanent injunctions
          without the necessity of posting a bond or other security, in addition
          to and without prejudice to any other rights or remedies that Capital
          Velocity may have for a breach of these Terms. These Terms, which you
          accept upon registration for the Services, the Privacy Policy located
          on the Site, and any applicable payment, renewal, additional services
          terms, comprise the entire agreement between you and Capital Velocity
          regarding the use of this Service, superseding any prior agreements
          between you and Capital Velocity related to your use of this Site or
          Services (including, but not limited to, any prior versions of this
          Terms). The FAQ’s found on the Services are for informational purposes
          only and are not deemed to be part of this Terms. Unless otherwise
          explicitly stated, the Terms will survive termination of your
          registration to the Service. The failure of Capital Velocity to
          exercise or enforce any right or provision of these Terms does not
          constitute a waiver of such right or provision. If any provision of
          these Terms is held invalid, the remainder of the Terms will continue
          in full force and effect. Regardless of any statute or law to the
          contrary, any claim or cause of action arising out of or related to
          the use of the Site or the Terms must be filed within one (1) year
          after such claim or cause of action arose or be forever barred. The
          section titles in the Terms are for convenience only and have no legal
          <br></br>
          <br></br>
          <strong>11. User Information:</strong>
          <br></br>
          <br></br>
          <ol type="a">
            <li>
              Privacy Policy. For information about the collection and possible
              use of information and material provided by you, please visit
              Capital Velocity’s Privacy Policy located on the Site. By using
              the Site or the Services, you are consenting to the terms of
              Capital Velocity’s Privacy Policy.
            </li>
            <li>
              Disclosure by Law. You acknowledge and agree that Capital Velocity
              may disclose information you provide if required to do so by law,
              at the request of a third-party, or if we, in our sole discretion,
              believe that disclosure is reasonable to (i) comply with the law,
              requests or orders from law enforcement, or any legal process
              (whether or not such disclosure is required by applicable law); or
              (ii) protect or defend Capital Velocity’s, or a third-party’s,
              rights or property.
            </li>
            <li>
              Information Security. We work hard to protect Capital Velocity and
              our users from unauthorized access to or unauthorized alteration,
              disclosure or destruction of information we hold. In particular:
              <ul>
                <li>
                  We encrypt many of our services using industry standard
                  methods.
                </li>
                <li>
                  We review our information collection, storage and processing
                  practices, including physical security measures, to guard
                  against unauthorized access to systems.
                </li>
                <li>
                  We restrict access to personal information to Capital Velocity
                  employees, contractors and agents who need to know that
                  information in order to process it for us, and who are subject
                  to strict contractual confidentiality obligations and may be
                  disciplined or terminated if they fail to meet these
                  obligations.
                </li>
              </ul>
            </li>
            <li>
              Use of Anonymous Information for Research. By using the Site
              and/or Services, you agree to allow Capital Velocity to
              anonymously use the information from you and your experiences
              through the Services to continue Capital Velocity’s research into
              successful business practices and to improve the Services.
            </li>
            <li>
              License Regarding Your Content. You retain all ownership interest
              in and to the content you provide to the Site or as part of your
              use of the Services. However, by submitting your content,
              including your personal and business information, you hereby grant
              to Capital Velocity a perpetual, non-exclusive, irrevocable,
              royalty-free license to reproduce, adapt, edit, modify, translate,
              publish, transfer, and/or distribute any of your content. This
              license specifically includes the right for Capital Velocity to
              make such content available to other trusted companies,
              organizations, and/or individuals with whom Capital Velocity has a
              business relationship in order to carry out the performance of the
              Services. As part of its performance of the Services, Capital
              Velocity may (i) transmit or distribute your information over
              various public networks and in various forms; and (ii) make
              necessary changes to your content in order to perform its
              obligations.
            </li>
          </ol>
          <br></br>
          <br></br>
          <strong>12. Restrictions:</strong>
          <br></br>
          <br></br>
          You may not, and will make all reasonable efforts to make sure any
          third parties do not: Use the Services as a money services business,
          money transmitter, payment intermediary, aggregator, or service bureau
          or resell the Services in any manner. Use the Services on behalf of a
          third party in any manner. Abuse the Services in any manner. Use the
          Service in violation of any other agreement you may have with any of
          your financial accounts. Create a similar product or service through
          use of or access to the Service. Use the Service in violation of any
          law, regulation, or rule. Use the Service to perpetuate or commit a
          fraud of any kind. Use the Services in any way that is not authorized
          by Capital Velocity. Use the Service for personal bank accounts.
          <br></br>
          <br></br>
          <strong>13. Content:</strong>
          <br></br>
          <br></br>
          All text, graphics, user interfaces, visual interfaces, photographs,
          trademarks (whether registered or not), logos, sounds, artwork of any
          kind, and computer code (collectively, “Content”), including but not
          limited to the design, structure, coordination, selection, appearance,
          and arrangement of the Content, contained on the Site is owned by
          Capital Velocity and is protected by trade dress, copyright,
          trademark, and other property laws and unfair competition laws. Any
          misuse of the Content will be at your peril and Capital Velocity will
          do all that is lawful to enforce and protect the Content.
          <br></br>
          <br></br>
          <strong>14. Confidentiality:</strong>
          <br></br>
          <br></br>
          We will take every reasonable precaution to protect your account and
          account information, however, we cannot guarantee the security of any
          information you disclose. This includes your sharing of account
          information with any third party, including employees, contractors,
          vendors, suppliers, etc. of your business. You agree and acknowledge
          that the security of your data is your responsibility. Your use of the
          Services is further governed by our Privacy Policy.
          <br></br>
          <br></br>
          <strong>15. Notification of Terms of Use Changes:</strong>
          <br></br>
          <br></br>
          We may update these Terms of Use to reflect changes to our business
          operations and the methods by which we work with you, including
          changing vendors, and other policies relevant to our Services. If we
          make any material changes we will notify you by email (sent to the
          email address specified in your account) or by means of a notice on
          this Site. We encourage you to periodically review this page for the
          latest information on our Terms of Use. When we make changes to these
          Terms of Use we will revise the revision date at the top of the Terms
          of Use.
          <br></br>
          <br></br>
          <strong>16. Term and Termination:</strong>
          <br></br>
          <br></br>
          This agreement will become effective upon your acceptance of the Terms
          by your use of the Site or Services and will remain in effect in
          perpetuity unless terminated hereunder. Capital Velocity may terminate
          your account at any time, for any reason or no reason, without
          explanation, effective upon written notice to the other party. Capital
          Velocity reserves the right to immediately suspend or terminate your
          access to any of the Services, without notice, for any reason or no
          reason. We also reserve the right to refuse to remove your account
          information or data from our Services and any other records at any
          time to comply with legal and regulatory requirements. In the event
          your access to any of the Services is suspended due to the breach of
          these Terms, you agree that all fees then paid to Capital Velocity by
          you will be nonrefundable and all outstanding or pending payments will
          immediately be due. You may terminate your account by following the
          steps in the applicable section under “Cancellations.” Sections 10 and
          11 shall survive any termination of this Agreement.
        </Typography>
      </Container>
      <Footer2 />
    </div>
  );
}

export default TermsofUse;
