/* eslint-disable react/no-unescaped-entities */
import React from "react";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMoneyBill,
  faClockRotateLeft,
  faCashRegister,
  faScrewdriverWrench,
  faBuildingColumns,
  faRocket,
  faFolderMinus,
  faTag,
} from "@fortawesome/free-solid-svg-icons";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Container from "../screens/Container";

const mock = [
  {
    title: "Resturants & Food Service",
    icon: <FontAwesomeIcon icon={faMoneyBill} />,
  },
  {
    title: "Fitness",
    icon: <FontAwesomeIcon icon={faClockRotateLeft} />,
  },
  {
    title: "Nonprofit",
    icon: <FontAwesomeIcon icon={faCashRegister} />,
  },
  {
    title: "HealthCare",
    icon: <FontAwesomeIcon icon={faScrewdriverWrench} />,
  },
  {
    title: "eCommerce",
    icon: <FontAwesomeIcon icon={faBuildingColumns} />,
  },
  {
    title: "Fashion",
    icon: <FontAwesomeIcon icon={faRocket} />,
  },
  {
    title: "Construction",
    icon: <FontAwesomeIcon icon={faFolderMinus} />,
  },
  {
    title: "Retail",
    icon: <FontAwesomeIcon icon={faTag} />,
  },
];

const Industry = () => {
  const theme = useTheme();
  return (
    <Box style={{ backgroundColor: "#F6F5F3" }}>
      <Box marginBottom={4}>
        <Typography
          variant="h4"
          align={"center"}
          data-aos={"fade-up"}
          gutterBottom
          sx={{
            fontWeight: 300,
            color: "black",
          }}
        >
          Find success stories from your industry
        </Typography>
      </Box>
      <Box>
        <Container style={{ marginTop: "-50px" }}>
          <Grid container spacing={2}>
            {mock.map((item, i) => (
              <Grid item xs={3} md={3} key={i}>
                <Box
                  display={"block"}
                  width={1}
                  height={1}
                  sx={{
                    textDecoration: "none",
                    transition: "all .2s ease-in-out",
                    "&:hover": {
                      transform: "translateY(-4px)",
                    },
                  }}
                >
                  <Box
                    component={Card}
                    padding={2}
                    width={1}
                    height={1}
                    borderRadius={2}
                    bgcolor={"alternate.main"}
                    data-aos={"fade-up"}
                    data-aos-delay={i * 100}
                    data-aos-offset={100}
                    data-aos-duration={600}
                  >
                    <Box
                      position={"relative"}
                      display={"flex"}
                      justifyContent={"center"}
                    >
                      <Box
                        sx={{
                          color: "#498dd6",

                          bottom: 0,
                        }}
                      >
                        {item.icon}
                      </Box>
                    </Box>
                    <Typography
                      variant={"subtitle1"}
                      align={"center"}
                      sx={{ fontWeight: 500, marginTop: 2 }}
                    >
                      {item.title}
                    </Typography>
                  </Box>
                </Box>
              </Grid>
            ))}
          </Grid>
          <Box marginTop={2} display={"flex"} justifyContent={"center"}>
            <Button
              variant="outlined"
              color="primary"
              size="large"
              style={{
                borderRadius: "30px",
                color: "#498dd6",
                borderColor: "#498dd6",
              }}
            >
              View all Loan Types
            </Button>
          </Box>
        </Container>
      </Box>
    </Box>
  );
};

export default Industry;
