import React from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import Box from "@mui/material/Box";
import { Grid, Avatar } from "../../node_modules/@mui/material/index";
import IconButton from "@mui/material/IconButton";
import Input from "@mui/material/Input";
import FilledInput from "@mui/material/FilledInput";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import FormHelperText from "@mui/material/FormHelperText";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import headerPageLady from "../Images/headerPageLady.png";
// These are the image imports
import bannerBlog from "../Images/bannerBlog.png";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import ent from "../Images/ent.png";
import cnbc2 from "../Images/cnbc2.png";
import forbes from "../Images/forbes.png";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import useMediaQuery from "@mui/material/useMediaQuery";
import { alpha, useTheme } from "@mui/material/styles";
import { Link } from "react-router-dom";
import Container from "../screens/Container";
import { Card } from "../../node_modules/@mui/material/index";
import Alert from "@mui/material/Alert";

const BlogHeader = () => {
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up("md"), {
    defaultMatches: true,
  });

  const mock = [
    {
      title: "Google Drive",
      subtitle:
        "A very simple and modern template with a very harmonious color scheme. Also the additional plugins like the statistics are great and fit perfectly into the overall picture.",
      icon: { cnbc2 },
    },
    {
      title: "Google Ad Manager",
      subtitle:
        "A very simple and modern template with a very harmonious color scheme. Also the additional plugins like the statistics are great and fit perfectly into the overall picture.",
      icon: { ent },
    },
    {
      title: "Atlassian",
      subtitle:
        "Keep your entire team in sync with development and easily manage tasks, goals, and deadlines. Easily manage and edit any Adwords campaign inline to improve ROI with constant review.",
      icon: { forbes },
    },
  ];

  const LeftSide = () => (
    <Box>
      <Box marginBottom={3}>
        <Typography
          variant="h4"
          component="p"
          color="black"
          sx={{ fontWeight: 100, color: "black" }}
        >
          Faster, easier small business financing – no bank needed.
        </Typography>
      </Box>
      <Stack spacing={2} direction="row">
        <Button
          variant="contained"
          style={{
            backgroundColor: "#498dd6",

            borderRadius: "30px",
          }}
        >
          See Options
        </Button>
        <Button
          variant="outlined"
          style={{
            color: "#498dd6",
            borderColor: "#498dd6",
            borderRadius: "30px",
          }}
        >
          Learn More
        </Button>
      </Stack>
    </Box>
  );
  const MainBox = () => {
    return (
      <Box
        style={{ height: "200px", borderRadius: "10px" }}
        component={Card}
        boxShadow={1}
      >
        <Container>
          <Box>
            <Grid container spacing={2}>
              <Grid item xs={12} md={4}>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <Box component="img" marginBottom={1} src={cnbc2} />
                  <Typography sx={{ fontStyle: "italic" }} align={"center"}>
                    “What used to take months is now being accomplished in
                    weeks.”
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12} md={4}>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <Box component="img" marginBottom={1} src={ent} />
                  <Typography sx={{ fontStyle: "italic" }} align={"center"}>
                    “Lendio is a simple process for business owners.”
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12} md={4}>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <Box component="img" marginBottom={1} src={forbes} />
                  <Typography sx={{ fontStyle: "italic" }} align={"center"}>
                    “Lendio introduces business owners to active lenders.”
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Container>
      </Box>
    );
  };
  const RightSide = () => {
    return (
      <Box>
        <Box
          component={LazyLoadImage}
          src={bannerBlog}
          width={1}
          style={{
            width: "500px",
            marginTop: "10px",
            marginRight: "10px",
            marginLeft: "50px",
          }}
          maxWidth={1}
        />
      </Box>
    );
  };

  return (
    <Box style={{ backgroundColor: "#F6F5F6" }}>
      <Container paddingX={0} paddingY={0} maxWidth={{ sm: 1, md: 1236 }}>
        <Box
          display={"flex"}
          flexDirection={{ xs: "column", md: "row" }}
          position={"relative"}
        >
          <Box
            width={1}
            order={{ xs: 2, md: 1 }}
            display={"flex"}
            alignItems={"center"}
          >
            <Container>
              <LeftSide />
            </Container>
          </Box>
          <Box
            sx={{
              flex: { xs: "0 0 100%", md: "0 0 50%" },
              position: "relative",
              maxWidth: { xs: "100%", md: "50%" },
              order: { xs: 1, md: 2 },
            }}
          >
            <Box
              sx={{
                width: { xs: 1, md: "50vw" },
                height: "100%",
                position: "relative",
              }}
            >
              <Box
                sx={{
                  width: "100%",
                  height: "100%",
                  overflow: "hidden",
                }}
              >
                <Box>
                  <RightSide />
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Container>
      <Divider />
    </Box>
  );
};

export default BlogHeader;
