import React from "react";
import Box from "@mui/material/Box";
import FrontCover from "../components/FrontCover";
import { useTheme } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import { Typography } from "@mui/material";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import Container from "../screens/Container";
import StepLabel from "@mui/material/StepLabel";
import StepContent from "@mui/material/StepContent";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import phone333 from "../Images/phone333.png";
import { Divider } from "../../node_modules/@mui/material/index";
const stepStyle = {
  "& .Mui-active": {
    "&.MuiStepIcon-root": {
      color: "#498dd6",
      fontSize: "3rem",
    },
  },
};
const steps = [
  {
    label: "Apply fast.",
    description: `Fill out our simple application in minutes with no
      impact to your credit.`,
  },
  {
    label: "Get connected.",
    description:
      "An ad group contains one or more ads which target a shared set of keywords.",
  },
  {
    label: "Compare offers.",
    description: `Lendio partners with 75+ lenders to bring you custom
      funding offers for your business.`,
  },
  {
    label: "Get funded.",
    description: `Pick an offer and receive capital for your business in
      as little as 24 hours.`,
  },
];
function TypesofLoansConstruction() {
  const theme = useTheme();
  return (
    <div>
      <Box
        display={"flex"}
        flexDirection={{ xs: "column", md: "row" }}
        position={"relative"}
      >
        <Box
          width={1}
          order={{ xs: 2, md: 1 }}
          display={"flex"}
          alignItems={"center"}
        >
          <Container>
            <Box>
              <Typography
                variant="h4"
                align={"center"}
                gutterBottom
                sx={{
                  color: "black",
                  fontWeight: 300,
                  marginTop: theme.spacing(1),
                }}
              >
                Types of Business Loans For Heavy Equipment
              </Typography>
            </Box>
          </Container>
        </Box>
      </Box>
      <Container style={{ backgroundColor: "#F5F5F5" }}>
        <Grid container spacing={2}>
          <Grid item sm={6}>
            <Typography
              variant="h5"
              gutterBottom
              sx={{
                color: "black",
                fontWeight: 600,
                marginTop: theme.spacing(1),
              }}
            >
              Asset or Revenue-Based Financing
            </Typography>
            <Typography
              variant="p"
              gutterBottom
              sx={{
                color: "black",
                fontWeight: 100,
                marginTop: theme.spacing(1),
              }}
            >
              Consider getting an advance against assets or future revenue that
              has yet to be paid. You typically get a lump sum up front that you
              can use for a down payment on heavy equipment, delivery fees, or
              training costs. Once that revenue comes in, you’ll repay the
              advance as well as a lender fee.
            </Typography>
          </Grid>
          <Grid item sm={6} style={{ marginTop: "50px" }}>
            <Grid container spacing={2}>
              <Grid item sm={6}>
                <Typography
                  variant="p"
                  gutterBottom
                  sx={{
                    color: "black",
                    fontWeight: 100,
                    marginTop: theme.spacing(1),
                  }}
                >
                  TERM LENGTH
                </Typography>
                <Divider style={{ backgroundColor: "grey" }} />
                <Typography
                  variant="p"
                  gutterBottom
                  sx={{
                    color: "black",
                    fontWeight: 500,
                    marginTop: theme.spacing(1),
                  }}
                >
                  1-15 months
                </Typography>
              </Grid>
              <Grid item sm={6}>
                <Typography
                  variant="p"
                  gutterBottom
                  sx={{
                    color: "black",
                    fontWeight: 100,
                    marginTop: theme.spacing(1),
                  }}
                >
                  MAX LOAN AMOUNT
                </Typography>
                <Divider style={{ backgroundColor: "grey" }} />
                <Typography
                  variant="p"
                  gutterBottom
                  sx={{
                    color: "black",
                    fontWeight: 500,
                    marginTop: theme.spacing(1),
                  }}
                >
                  Up to 90% of receivables
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
      {/* Second*/}
      <Container style={{ backgroundColor: "#F5F5F5", marginTop: "20px" }}>
        <Grid container spacing={2}>
          <Grid item sm={6}>
            <Typography
              variant="h5"
              gutterBottom
              sx={{
                color: "black",
                fontWeight: 600,
                marginTop: theme.spacing(1),
              }}
            >
              Debt Financing
            </Typography>
            <Typography
              variant="p"
              gutterBottom
              sx={{
                color: "black",
                fontWeight: 100,
                marginTop: theme.spacing(1),
              }}
            >
              Both online term loans and SBA loans can be used to finance heavy
              equipment and related soft costs. Online loans come with the
              fastest turnaround times and easiest application processes, while
              there are several types of SBA loans designed for major asset
              purchases like equipment.
            </Typography>
          </Grid>
          <Grid item sm={6} style={{ marginTop: "50px" }}>
            <Grid container spacing={2}>
              <Grid item sm={6}>
                <Typography
                  variant="p"
                  gutterBottom
                  sx={{
                    color: "black",
                    fontWeight: 100,
                    marginTop: theme.spacing(1),
                  }}
                >
                  TERM LENGTH
                </Typography>
                <Divider style={{ backgroundColor: "grey" }} />
                <Typography
                  variant="p"
                  gutterBottom
                  sx={{
                    color: "black",
                    fontWeight: 500,
                    marginTop: theme.spacing(1),
                  }}
                >
                  5-30 years
                </Typography>
              </Grid>
              <Grid item sm={6}>
                <Typography
                  variant="p"
                  gutterBottom
                  sx={{
                    color: "black",
                    fontWeight: 100,
                    marginTop: theme.spacing(1),
                  }}
                >
                  MAX LOAN AMOUNT
                </Typography>
                <Divider style={{ backgroundColor: "grey" }} />
                <Typography
                  variant="p"
                  gutterBottom
                  sx={{
                    color: "black",
                    fontWeight: 500,
                    marginTop: theme.spacing(1),
                  }}
                >
                  $5 million
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
      <Container style={{ backgroundColor: "#F5F5F5", marginTop: "20px" }}>
        <Grid container spacing={2}>
          <Grid item sm={6}>
            <Typography
              variant="h5"
              gutterBottom
              sx={{
                color: "black",
                fontWeight: 600,
                marginTop: theme.spacing(1),
              }}
            >
              Line of Credit
            </Typography>
            <Typography
              variant="p"
              gutterBottom
              sx={{
                color: "black",
                fontWeight: 100,
                marginTop: theme.spacing(1),
              }}
            >
              A line of credit gives you ongoing access to funds you can borrow.
              So if you want to purchase some equipment now and wait to buy more
              as your projects grow, you can borrow only what you need at the
              time. And as you pay down your balance, your credit line is
              replenished and you can borrow more as needed.
            </Typography>
          </Grid>
          <Grid item sm={6} style={{ marginTop: "50px" }}>
            <Grid container spacing={2}>
              <Grid item sm={6}>
                <Typography
                  variant="p"
                  gutterBottom
                  sx={{
                    color: "black",
                    fontWeight: 100,
                    marginTop: theme.spacing(1),
                  }}
                >
                  TERM LENGTH
                </Typography>
                <Divider style={{ backgroundColor: "grey" }} />
                <Typography
                  variant="p"
                  gutterBottom
                  sx={{
                    color: "black",
                    fontWeight: 500,
                    marginTop: theme.spacing(1),
                  }}
                >
                  1-2 years
                </Typography>
              </Grid>
              <Grid item sm={6}>
                <Typography
                  variant="p"
                  gutterBottom
                  sx={{
                    color: "black",
                    fontWeight: 100,
                    marginTop: theme.spacing(1),
                  }}
                >
                  MAX LOAN AMOUNT
                </Typography>
                <Divider style={{ backgroundColor: "grey" }} />
                <Typography
                  variant="p"
                  gutterBottom
                  sx={{
                    color: "black",
                    fontWeight: 500,
                    marginTop: theme.spacing(1),
                  }}
                >
                  Up to $500,000
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
      <Container style={{ backgroundColor: "#F5F5F5", marginTop: "20px" }}>
        <Grid container spacing={2}>
          <Grid item sm={6}>
            <Typography
              variant="h5"
              gutterBottom
              sx={{
                color: "black",
                fontWeight: 600,
                marginTop: theme.spacing(1),
              }}
            >
              Equipment Financing
            </Typography>
            <Typography
              variant="p"
              gutterBottom
              sx={{
                color: "black",
                fontWeight: 100,
                marginTop: theme.spacing(1),
              }}
            >
              Using an equipment loan for your purchase comes with competitive
              rates because the equipment itself is used as collateral. You can
              borrow large amounts and many lenders even allow you to finance
              soft costs like taxes and delivery.
            </Typography>
          </Grid>
          <Grid item sm={6} style={{ marginTop: "50px" }}>
            <Grid container spacing={2}>
              <Grid item sm={6}>
                <Typography
                  variant="p"
                  gutterBottom
                  sx={{
                    color: "black",
                    fontWeight: 100,
                    marginTop: theme.spacing(1),
                  }}
                >
                  TERM LENGTH
                </Typography>
                <Divider style={{ backgroundColor: "grey" }} />
                <Typography
                  variant="p"
                  gutterBottom
                  sx={{
                    color: "black",
                    fontWeight: 500,
                    marginTop: theme.spacing(1),
                  }}
                >
                  1-5 years
                </Typography>
              </Grid>
              <Grid item sm={6}>
                <Typography
                  variant="p"
                  gutterBottom
                  sx={{
                    color: "black",
                    fontWeight: 100,
                    marginTop: theme.spacing(1),
                  }}
                >
                  MAX LOAN AMOUNT
                </Typography>
                <Divider style={{ backgroundColor: "grey" }} />
                <Typography
                  variant="p"
                  gutterBottom
                  sx={{
                    color: "black",
                    fontWeight: 500,
                    marginTop: theme.spacing(1),
                  }}
                >
                  $5 million
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}

export default TypesofLoansConstruction;
