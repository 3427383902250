import React from "react";
import Box from "@mui/material/Box";
import FrontCover from "../components/FrontCover";
import { useTheme } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import { Typography } from "@mui/material";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import Container from "../screens/Container";
import StepLabel from "@mui/material/StepLabel";
import StepContent from "@mui/material/StepContent";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import phone333 from "../Images/phone333.png";
import ResourcesCard from "../components/ResourcesCard";
import { Divider } from "../../node_modules/@mui/material/index";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
function FAQConstruction() {
  const theme = useTheme();
  return (
    <div>
      <Box
        display={"flex"}
        flexDirection={{ xs: "column", md: "row" }}
        position={"relative"}
      >
        <Box
          width={1}
          order={{ xs: 2, md: 1 }}
          display={"flex"}
          alignItems={"center"}
        >
          <Container>
            <Box>
              <Typography
                variant="h4"
                align={"center"}
                gutterBottom
                sx={{
                  color: "black",
                  fontWeight: 300,
                  marginTop: theme.spacing(1),
                }}
              >
                FAQ
              </Typography>
              <div
                style={{
                  width: "75%",
                  alignContent: "center",
                  marginLeft: "13.5%",
                }}
              >
                <Accordion style={{ boxShadow: "none" }}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography
                      variant="h6"
                      sx={{
                        color: "black",
                        fontWeight: 600,
                      }}
                    >
                      What is heavy equipment financing?
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>
                      Heavy equipment financing is a type of loan where a lender
                      or other financier provides funding for the borrower to
                      purchase heavy equipment, like bulldozers. Lenders offer
                      these loans for small businesses in the construction
                      industry that either don’t want to use their working
                      capital to buy the equipment up front or can’t pay for the
                      equipment in cash. The borrower then repays the loan with
                      interest over a specified time period. In heavy equipment
                      financing arrangements, the heavy equipment itself usually
                      serves as collateral for the funding. In some cases,
                      additional collateral may be required to secure the
                      financing.
                    </Typography>
                  </AccordionDetails>
                </Accordion>
                <Accordion style={{ boxShadow: "none" }}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography
                      variant="h6"
                      sx={{
                        color: "black",
                        fontWeight: 600,
                      }}
                    >
                      What are the requirements to get a heavy equipment
                      financing?
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>
                      Heavy equipment financing can be used for any type of
                      equipment or machinery your company may need, including
                      bulldozers, pavers, lifts, compactors, and trucks.
                    </Typography>
                  </AccordionDetails>
                </Accordion>
                <Accordion style={{ boxShadow: "none" }}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography
                      variant="h6"
                      sx={{
                        color: "black",
                        fontWeight: 600,
                      }}
                    >
                      Heavy equipment: leasing vs. financing?
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>
                      Leasing and financing heavy equipment are similar in that
                      the person obtaining the equipment won’t need to purchase
                      the machinery outright before using it. Instead, the
                      leasee or borrower makes monthly payments on the
                      equipment.
                      <br></br>
                      But financing and leasing also have their differences. For
                      example, an equipment lease has a fixed term with a
                      monthly rental fee that can’t be paid off early, while an
                      equipment loan can be paid off early, with any remaining
                      interest wiped clean.
                      <br></br>
                      Leasing also allows you to use the equipment itself as
                      collateral, while you may need to provide additional
                      collateral to secure equipment financing.
                      <br></br>
                      There are multiple structures available for equipment
                      leasing. Depending on the structure, at the end of the
                      lease you may either own the equipment in full, exercise
                      the option to purchase the equipment, or return the
                      equipment.
                    </Typography>
                  </AccordionDetails>
                </Accordion>
                <Accordion style={{ boxShadow: "none" }}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography
                      variant="h6"
                      sx={{
                        color: "black",
                        fontWeight: 600,
                      }}
                    >
                      How hard is it to get a heavy equipment loan?
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>
                      Heavy equipment loans are not as difficult to qualify for
                      as some other forms of financing, like a traditional term
                      loan from a bank. If you have been in business for several
                      years and have a credit score of 620 or above, you have
                      great chances of qualifying. The higher your credit score
                      and the longer you’ve been in business, the higher the
                      probability that you will qualify and get a favorable rate
                      and term. If your business is very new, you might still
                      qualify for heavy equipment financing if you have a higher
                      credit score.
                    </Typography>
                  </AccordionDetails>
                </Accordion>
                <Accordion style={{ boxShadow: "none" }}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography
                      variant="h6"
                      sx={{
                        color: "black",
                        fontWeight: 600,
                      }}
                    >
                      How many years can you finance heavy equipment?
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>
                      Generally, heavy equipment financing has repayment terms
                      that are linked to the expected lifespan of the equipment
                      being financed. This is so the borrower isn’t paying a
                      loan on equipment that can no longer be used. Expect heavy
                      equipment loans to have repayment periods from one to five
                      years, with some lenders offering terms up to 10 years.
                    </Typography>
                  </AccordionDetails>
                </Accordion>
                <Accordion style={{ boxShadow: "none" }}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography
                      variant="h6"
                      sx={{
                        color: "black",
                        fontWeight: 600,
                      }}
                    >
                      What is an SBA Loan?
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>
                      An SBA loan is a loan that is secured by the U.S. Small
                      Business Administration. This means the SBA is not
                      responsible for processing loan applications and funding
                      loan amounts, but it will pay any unpaid balances to the
                      lender if the borrower defaults. Because of this, the SBA
                      is the one that establishes borrower eligibility
                      requirements. SBA loans offer more borrower-friendly
                      interest rates and repayment periods, but can also be
                      harder to qualify for and take longer to process than
                      other small business loans.
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              </div>
            </Box>
          </Container>
        </Box>
      </Box>
    </div>
  );
}

export default FAQConstruction;
