import React from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Link from "@mui/material/Link";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";
import Container from "../screens/Container";
import doctor from "../Images/doctor.jpeg";
const FrontCoverMedicine = () => {
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up("md"), {
    defaultMatches: true,
  });

  return (
    <Box
      sx={{
        width: 1,
        height: 1,
        overflow: "hidden",
      }}
      style={{ backgroundColor: "#F5F5F4" }}
    >
      <div>
        <Box
          display={"flex"}
          flexDirection={{ xs: "column", md: "row" }}
          position={"relative"}
        >
          <Box
            width={1}
            order={{ xs: 2, md: 1 }}
            display={"flex"}
            alignItems={"center"}
          >
            <Container>
              <Box>
                <Typography
                  variant="h4"
                  align={"center"}
                  gutterBottom
                  sx={{
                    color: "black",
                    fontWeight: 700,
                    marginTop: theme.spacing(1),
                  }}
                >
                  Medical Practice Loans
                </Typography>
                <Typography
                  variant="p"
                  component="p"
                  align={"center"}
                  gutterBottom
                  color="black"
                  style={{ fontWeight: "700" }}
                >
                  Faster, easier small business financing with one simple
                  application—no bank needed.
                </Typography>
                <Typography
                  variant="p"
                  component="p"
                  align={"center"}
                  gutterBottom
                  color="black"
                  style={{ fontWeight: "100", marginTop: "20px" }}
                >
                  How much money do you need?
                </Typography>
                <FormControl
                  align={"center"}
                  fullWidth
                  sx={{
                    m: 1,
                    ml: 15,
                    minWidth: 120,
                    maxWidth: 500,
                    align: "center",
                    justifyContent: "center",
                  }}
                >
                  <InputLabel id="demo-simple-select-helper-label">
                    Please Choose a Value
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-helper-label"
                    id="demo-simple-select-helper"
                    label="Age"
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    <MenuItem value={10}>Ten</MenuItem>
                    <MenuItem value={20}>Twenty</MenuItem>
                    <MenuItem value={30}>Thirty</MenuItem>
                  </Select>
                </FormControl>
                <Box
                  marginTop={4}
                  display={"flex"}
                  justifyContent={{ xs: "center", md: "center" }}
                >
                  <Button
                    variant="contained"
                    size="large"
                    style={{
                      backgroundColor: "#498dd6",

                      borderRadius: "30px",
                    }}
                  >
                    See Options
                  </Button>
                </Box>
                <Typography
                  variant="p"
                  component="p"
                  align={"center"}
                  gutterBottom
                  color="black"
                  style={{ fontWeight: "100", marginTop: "20px" }}
                >
                  Applying is free and won’t impact your credit.
                </Typography>
              </Box>
            </Container>
          </Box>
          <Box
            sx={{
              flex: { xs: "0 0 100%", md: "0 0 50%" },
              position: "relative",
              maxWidth: { xs: "100%", md: "50%" },
              order: { xs: 1, md: 2 },
              minHeight: { xs: "auto", md: "calc(100vh - 58px)" },
            }}
          >
            <Box
              sx={{
                width: { xs: 1, md: "50vw" },
                height: "100%",
                position: "relative",
              }}
            >
              <Box
                sx={{
                  width: "100%",
                  height: "100%",
                  overflow: "hidden",
                }}
              >
                <Box
                  sx={{
                    overflow: "hidden",
                    left: "0%",
                    width: 1,
                    height: 1,
                    position: { xs: "relative", md: "absolute" },

                    shapeOutside: {
                      xs: "none",
                      md: "polygon(10% 0%, 100% 0, 100% 100%, 0% 100%)",
                    },
                  }}
                >
                  <Box
                    sx={{
                      height: { xs: "auto", md: 1 },
                      "& img": {
                        objectFit: "cover",
                      },
                      "& .lazy-load-image-loaded": {
                        height: 1,
                        width: 1,
                      },
                    }}
                  >
                    <Box
                      component={LazyLoadImage}
                      effect="blur"
                      src={doctor}
                      height={{ xs: "auto", md: 1 }}
                      maxHeight={{ xs: 300, md: 1 }}
                      width={1}
                      maxWidth={1}
                      sx={{
                        filter:
                          theme.palette.mode === "dark"
                            ? "brightness(0.7)"
                            : "none",
                      }}
                    />
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </div>
    </Box>
  );
};

export default FrontCoverMedicine;
