import React from "react";
import Box from "@mui/material/Box";
import FrontCover from "../components/FrontCover";
import { useTheme } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import { Typography } from "@mui/material";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import Container from "../screens/Container";
import StepLabel from "@mui/material/StepLabel";
import StepContent from "@mui/material/StepContent";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import phone333 from "../Images/phone333.png";
import ResourcesCard from "../components/ResourcesCard";
import { Divider } from "../../node_modules/@mui/material/index";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
function FAQManufacturing() {
  const theme = useTheme();
  return (
    <div>
      <Box
        display={"flex"}
        flexDirection={{ xs: "column", md: "row" }}
        position={"relative"}
      >
        <Box
          width={1}
          order={{ xs: 2, md: 1 }}
          display={"flex"}
          alignItems={"center"}
        >
          <Container>
            <Box>
              <Typography
                variant="h4"
                align={"center"}
                gutterBottom
                sx={{
                  color: "black",
                  fontWeight: 300,
                  marginTop: theme.spacing(1),
                }}
              >
                FAQ
              </Typography>
              <div
                style={{
                  width: "75%",
                  alignContent: "center",
                  marginLeft: "13.5%",
                }}
              >
                <Accordion style={{ boxShadow: "none" }}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography
                      variant="h6"
                      sx={{
                        color: "black",
                        fontWeight: 600,
                      }}
                    >
                      What is a manufacturing loan?
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>
                      A manufacturing business loan provides borrowers with the
                      funds they need to purchase necessary equipment or heavy
                      machinery, grow their business, or pay for general
                      operations. While there are no loan products specific to
                      manufacturing, there are multiple business loan products
                      available to manufacturing companies.
                    </Typography>
                  </AccordionDetails>
                </Accordion>
                <Accordion style={{ boxShadow: "none" }}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography
                      variant="h6"
                      sx={{
                        color: "black",
                        fontWeight: 600,
                      }}
                    >
                      What are the requirements to get manufacturing loans?
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>
                      To apply for manufacturing business loans through the
                      Lendio platform, companies need to have existed for at
                      least six months and make $8,000 or more per month.
                      Business owners will also need to have a credit score of
                      600 or more. The amount you’ll be able to borrow will
                      largely depend on the above factors, as well as your
                      current debt-to-income ratio.
                    </Typography>
                  </AccordionDetails>
                </Accordion>
                <Accordion style={{ boxShadow: "none" }}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography
                      variant="h6"
                      sx={{
                        color: "black",
                        fontWeight: 600,
                      }}
                    >
                      What can I use a manufacturing business loan for?
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>
                      Minimum requirements for financing start at: A credit
                      score minimum of 600 Monthly revenue of $8,000 or more
                      Time In business of at least six months
                    </Typography>
                  </AccordionDetails>
                </Accordion>
                <Accordion style={{ boxShadow: "none" }}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography
                      variant="h6"
                      sx={{
                        color: "black",
                        fontWeight: 600,
                      }}
                    >
                      What are the benefits of an online lending platform?
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>
                      With Lendio’s online platform, it’s possible to complete a
                      business loan application in as little as 15 minutes and
                      receive the full loan amount in less than 24 hours. The
                      application connects you with a marketplace of lenders,
                      and a funding manager will connect with you and guide you
                      through the process.
                    </Typography>
                  </AccordionDetails>
                </Accordion>
                <Accordion style={{ boxShadow: "none" }}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography
                      variant="h6"
                      sx={{
                        color: "black",
                        fontWeight: 600,
                      }}
                    >
                      What are the different types of SBA loans?
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>
                      The Small Business Administration currently offers three
                      types of loan products: microloans, 7(a) loans, and 504
                      loans. Microloans offer loan amounts up to $50,000 with
                      repayment periods of up to seven years. 7(a) loans go up
                      to $5 million, and have loan terms of up to 10 years. They
                      are intended to help small businesses with general capital
                      needs. Borrowers can also take out loans up to $5 million
                      with 504 loans, but 504s are tailored for large asset
                      purchases. Because of this, they come with repayment
                      periods of up to 25 years.
                    </Typography>
                  </AccordionDetails>
                </Accordion>
                <Accordion style={{ boxShadow: "none" }}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography
                      variant="h6"
                      sx={{
                        color: "black",
                        fontWeight: 600,
                      }}
                    >
                      What is an SBA Loan?
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>
                      An SBA loan is a loan that is secured by the U.S. Small
                      Business Administration. This means the SBA is not
                      responsible for processing loan applications and funding
                      loan amounts, but it will pay any unpaid balances to the
                      lender if the borrower defaults. Because of this, the SBA
                      is the one that establishes borrower eligibility
                      requirements. SBA loans offer more borrower-friendly
                      interest rates and repayment periods, but can also be
                      harder to qualify for and take longer to process than
                      other small business loans.
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              </div>
            </Box>
          </Container>
        </Box>
      </Box>
    </div>
  );
}

export default FAQManufacturing;
