import React from "react";
import BlogHeader from "../components/BlogHeader";
import Results from "../components/Results";
import Container from "../screens/Container";
import Footer2 from "../components/Footer2";
import { Divider } from "../../node_modules/@mui/material/index";
function BlogPage() {
  return (
    <div>
      <BlogHeader />
      <Container>
        <Results />
      </Container>
      <Divider style={{ color: "grey" }} />
      <Footer2 />
    </div>
  );
}

export default BlogPage;
