import React from "react";
import FrontCoverMedicine from "../components/FrontCoverMedicine";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Container from "../screens/Container";
import { alpha } from "@mui/material/styles";
import { Typography, Button } from "@mui/material";
import Grid from "@mui/material/Grid";
import Footer2 from "../components/Footer2";
import FAQManufacturing from "../components/FAQManufacturing";
import loangraph from "../Images/loangraph.png";
import HandshakeIcon from "@mui/icons-material/Handshake";
import top3 from "../Images/top3.png";
import phone33 from "../Images/phone33.png";
import doctor1 from "../Images/doctor1.png";
import TypesofloansMedical from "../components/TypesofloanMedical";
import LoanMatching from "../components/LoanMatching";
import ChecklistRtlIcon from "@mui/icons-material/ChecklistRtl";
import ReviewsManufacture from "../components/ReviewsManufacture";
import AdditonalResources from "../components/AdditonalResources";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { Divider } from "../../node_modules/@mui/material/index";
function Medical() {
  const theme = useTheme();
  return (
    <div>
      <FrontCoverMedicine />
      <ReviewsManufacture />
      {/*This is under the header */}
      <Box
        display={"flex"}
        flexDirection={{ xs: "column", md: "row" }}
        position={"relative"}
      >
        <Box
          width={1}
          order={{ xs: 2, md: 1 }}
          display={"flex"}
          alignItems={"center"}
        >
          <Container>
            <Box>
              <Typography
                variant="h4"
                align={"center"}
                gutterBottom
                sx={{
                  color: "black",
                  fontWeight: 300,
                  marginTop: theme.spacing(1),
                }}
              >
                Lendio Knows Medical Practice Small Businesses
              </Typography>
              <Typography
                variant="p"
                align={"center"}
                gutterBottom
                sx={{
                  color: "black",
                  fontWeight: 200,
                  marginTop: theme.spacing(1),
                }}
              >
                Whether you’re looking to buy an existing medical practice,
                launch your own from the ground up, or grow the practice you
                already have, Lendio has you covered.
              </Typography>

              <Grid container spacing={2} style={{ marginTop: "30px" }}>
                <Grid item sm={6}>
                  <img src={doctor1} width="100%" />
                </Grid>
                <Grid item sm={6} style={{ marginTop: "50px" }}>
                  <Typography
                    variant="p"
                    align={"center"}
                    gutterBottom
                    sx={{
                      color: "black",
                      fontWeight: 400,
                      marginTop: theme.spacing(1),
                    }}
                  >
                    With a medical practice loan, you can start, operate, or
                    grow your medical business. There are many lenders who offer
                    solutions for all types of healthcare professionals, like
                    doctors and dentists. No matter what your goals are, a
                    medical practice loan may help you meet—or even exceed—them.
                  </Typography>
                </Grid>
              </Grid>
            </Box>
          </Container>
        </Box>
      </Box>
      {/*Another Section */}
      <Box style={{ backgroundColor: "#DCF3E0" }}>
        <Container>
          <Grid container spacing={2} style={{ marginTop: "30px" }}>
            <Grid item sm={6} style={{ marginTop: "100px" }}>
              <Typography
                variant="p"
                align={"center"}
                gutterBottom
                sx={{
                  color: "black",
                  fontWeight: 400,
                  marginTop: theme.spacing(1),
                }}
              >
                Ready to find the right business loan for your medical practice?
                Simply fill out one short application to get matched to a number
                of financing offers. We take the time and hassle out of
                financing your medical practice, so you can focus on what you’re
                good at: serving your patients and improving your bottom line.
              </Typography>
            </Grid>
            <Grid item sm={6}>
              <img src={loangraph} width="100%" />
            </Grid>
          </Grid>
        </Container>
      </Box>
      {/*This is under the header */}
      <Box
        display={"flex"}
        flexDirection={{ xs: "column", md: "row" }}
        position={"relative"}
        style={{ backgroundColor: "#F6F6F6" }}
      >
        <Box
          width={1}
          order={{ xs: 2, md: 1 }}
          display={"flex"}
          alignItems={"center"}
        >
          <Container>
            <Box>
              <Typography
                variant="h4"
                align={"center"}
                gutterBottom
                sx={{
                  color: "black",
                  fontWeight: 300,
                  marginTop: theme.spacing(1),
                }}
              >
                Why Lendio?
              </Typography>

              <Grid container spacing={2} style={{ marginTop: "30px" }}>
                <Grid item sm={6} style={{ marginTop: "50px" }}>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      flexWrap: "wrap",
                    }}
                  >
                    <ChecklistRtlIcon
                      style={{ fontSize: "70px", color: "#498dd6" }}
                    />
                    <Typography
                      variant="h5"
                      align={"center"}
                      gutterBottom
                      sx={{
                        color: "black",
                        fontWeight: 600,
                        marginTop: theme.spacing(1),
                      }}
                    >
                      A Short Application Process
                    </Typography>
                    <Typography
                      variant="p"
                      gutterBottom
                      sx={{
                        color: "black",
                        fontWeight: 100,
                        marginLeft: "60px",
                      }}
                    >
                      Complete your Lendio application in just 15 minutes so you
                      can move on with your day. Funding times are also fast,
                      once approved, with many borrowers receiving money in the
                      bank within 24 hours of approval.
                    </Typography>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      flexWrap: "wrap",
                    }}
                  >
                    <HandshakeIcon
                      style={{ fontSize: "70px", color: "#498dd6" }}
                    />
                    <Typography
                      variant="h5"
                      align={"center"}
                      gutterBottom
                      sx={{
                        color: "black",
                        fontWeight: 600,
                        marginTop: theme.spacing(1),
                      }}
                    >
                      Ongoing Professional Support
                    </Typography>
                    <Typography
                      variant="p"
                      gutterBottom
                      sx={{
                        color: "black",
                        fontWeight: 100,
                        marginLeft: "60px",
                      }}
                    >
                      Enjoy personalized support with a dedicated funding
                      manager who understands your manufacturing business.
                    </Typography>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      flexWrap: "wrap",
                    }}
                  >
                    <CheckCircleOutlineIcon
                      style={{ fontSize: "70px", color: "#498dd6" }}
                    />
                    <Typography
                      variant="h5"
                      align={"center"}
                      gutterBottom
                      sx={{
                        color: "black",
                        fontWeight: 600,
                        marginTop: theme.spacing(1),
                      }}
                    >
                      An Extended Lender Network
                    </Typography>
                    <Typography
                      variant="p"
                      gutterBottom
                      sx={{
                        color: "black",
                        fontWeight: 100,
                        marginLeft: "60px",
                      }}
                    >
                      With over 75 lenders in the Lendio network, you’ll find
                      the right financing option for your manufacturing loan
                      with just a single application.
                    </Typography>
                  </div>
                </Grid>
                <Grid item sm={6}>
                  <img src={phone33} width="100%" />
                </Grid>
              </Grid>
            </Box>
          </Container>
        </Box>
      </Box>
      <LoanMatching />
      <TypesofloansMedical />
      <AdditonalResources />
      <FAQManufacturing />
      <Box
        data-speed="0.2"
        position={"relative"}
        minHeight={{ xs: 400, sm: 500, md: 600 }}
        display={"flex"}
        alignItems={"center"}
        marginTop={2}
        paddingTop={13}
        id="agency__portfolio-item--js-scroll"
      >
        <Box
          className={"jarallax-img"}
          sx={{
            position: "absolute",
            objectFit: "cover",
            /* support for plugin https://github.com/bfred-it/object-fit-images */
            fontFamily: "object-fit: cover;",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            zIndex: -1,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            backgroundPosition: "center center",
            backgroundImage:
              "url(https://images.pexels.com/photos/5410069/pexels-photo-5410069.jpeg)",
          }}
        />
        <Box
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            width: 1,
            height: 1,
            background: alpha("#161c2d", 0.4),
            zIndex: 1,
          }}
        />
        <Container position={"relative"} zIndex={2}>
          <Box>
            <Typography
              variant="h3"
              component="p"
              color="text.primary"
              sx={{
                color: "common.white",
              }}
            >
              8 out of 10 Lendio employees* or their families own a small
              business
            </Typography>
            <Typography
              variant="p"
              component="p"
              color="text.primary"
              sx={{
                color: "common.white",
              }}
            >
              *based on 136 Lendio employees who responded to an internal poll
            </Typography>
          </Box>
        </Container>
      </Box>
      <Container>
        <Box bgcolor={"#498dd6"} borderRadius={2}>
          <Container>
            <Box
              display={"flex"}
              flexDirection={"column"}
              justifyContent={"center"}
              alignItems={"center"}
            >
              <Box marginBottom={4}>
                <Typography
                  variant="h4"
                  align={"center"}
                  gutterBottom
                  sx={{
                    fontWeight: 700,
                    color: theme.palette.common.white,
                  }}
                >
                  Quickly Compare Loan Offers from Multiple Lenders
                </Typography>
                <Typography
                  variant="h6"
                  align={"center"}
                  sx={{
                    color: theme.palette.common.white,
                  }}
                >
                  Applying is free and won’t impact your credit
                </Typography>
                <Button
                  align={"center"}
                  variant="contained"
                  style={{
                    color: "#498dd6",
                    backgroundColor: "white",
                    marginLeft: "40%",
                    borderRadius: "30px",
                  }}
                >
                  Apply for Financing
                </Button>
              </Box>
              <Box width={1} display={"flex"} justifyContent={"center"}></Box>
            </Box>
          </Container>
        </Box>
      </Container>
      <Divider style={{ color: "grey" }} />
      <Footer2 />
    </div>
  );
}

export default Medical;
