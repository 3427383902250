import React from "react";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Container from "../../screens/Container";
import FeaturedArticles from "./FeaturedArticles";
import FooterNewsletter from "./FooterNewsletter";
import Hero from "./Hero";
import Footer2 from "../../components/Footer2"
import LatestStories from "./LatestStories";
import MostViewedArticles from "./MostViewedArticles";
import PopularNews from "./PopularNews";
import SidebarArticles from "./SidebarArticles";
import FeaturesWithCardRepresentation from "./FeaturesWithCardRepresentation";
import SidebarNewsletter from "./SidebarNewsletter";
import Tags from "./Tags";
const BlogNewsroom = () => {
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up("md"), {
    defaultMatches: true,
  });

  return (
    <div>
      <Hero />
      <Container>
        <PopularNews />
      </Container>
      <Box bgcolor={"alternate.main"}>
        <Container>
          <FeaturedArticles />
        </Container>
      </Box>
      <Container>
        <Grid container spacing={isMd ? 4 : 2}>
          <Grid item xs={12} md={8}>
            <LatestStories />
            <Divider style={{ color: "grey" }} />
          </Grid>
          {isMd ? (
            <Grid item xs={12} md={4}>
              <SidebarArticles />
            </Grid>
          ) : null}
        </Grid>
      </Container>
      <FeaturesWithCardRepresentation />
      <Footer2/>
    </div>
  );
};

export default BlogNewsroom;
