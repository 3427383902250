/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import { alpha, useTheme } from '@mui/material/styles';
import Card from '@mui/material/Card';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import InputAdornment from '@mui/material/InputAdornment';

import Container from "../../screens/Container";

const Hero = () => {
  const theme = useTheme();

  return (
    <Box
      minHeight={{ xs: 200, sm: 300 }}
      position={'relative'}
      sx={{
        backgroundColor: "white",
        background:
          'url(https://assets.maccarianagency.com/backgrounds/img52.jpg) no-repeat center',
        backgroundSize: 'cover',
        marginTop: -12,
        paddingTop: 12,
      }}
    >
      <Box
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          width: 1,
          height: 1,
          background: alpha('#161c2d', 0.4),
          zIndex: 1,
        }}
      />
      <Container position={'relative'} zIndex={2}>
        <Box>
          <Box marginBottom={4} data-aos="fade-up">
            <Typography
              variant="h3"
              gutterBottom
              sx={{
                fontWeight: 900,
                color: 'common.white',
              }}
            >
              Newsroom
            </Typography>
            <Typography
              variant="h6"
              component="p"
              color="text.primary"
              sx={{
                fontWeight: 400,
                color: 'common.white',
              }}
            >
             Stories of our nation’s small business owners, research on the issues that affect them most,
             <br/>
              and the most recent announcements from Lendio.
            </Typography>
          </Box>       
        </Box>
      </Container>
    </Box>
  );
};

export default Hero;
