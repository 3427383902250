import React from 'react'
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Footer2 from "../components/Footer2";
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import CardMedia from '@mui/material/CardMedia';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import ListItemText from '@mui/material/ListItemText';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import TwitterIcon from '@mui/icons-material/Twitter';
import GitHubIcon from '@mui/icons-material/GitHub';
import FacebookIcon from '@mui/icons-material/Facebook';
import Container from '../screens/Container';
function Leadership() {
    const theme = useTheme();
    const mock = [
        {
          name: 'Chary Smith',
          title: 'SEO at Comoti',
          avatar: 'https://assets.maccarianagency.com/avatars/img3.jpg',
          about:
            'I am an ambitious workaholic, but apart from that, pretty simple person.',
        },
        {
          name: 'Clara Bertoletti',
          title: 'Junior Designer',
          avatar: 'https://assets.maccarianagency.com/avatars/img4.jpg',
          about:
            'I am an ambitious workaholic, but apart from that, pretty simple person.',
        },
        {
          name: 'Jhon Anderson',
          title: 'Senior Frontend Developer',
          avatar: 'https://assets.maccarianagency.com/avatars/img5.jpg',
          about:
            'I am an ambitious workaholic, but apart from that, pretty simple person.',
        },
        {
          name: 'Chary Smith',
          title: 'SEO at Comoti',
          avatar: 'https://assets.maccarianagency.com/avatars/img6.jpg',
          about:
            'I am an ambitious workaholic, but apart from that, pretty simple person.',
        },
      ];
    return (
        <div>
        <Container>
            <Typography
                    variant="h4"
                    gutterBottom
                    sx={{
                      align: "center",
                      fontWeight: 400,
                      color: "black",
                    }}
                    style={{marginBottom:"10px"}}
                  >
                    Lendio Leadership
                  </Typography>
                  <Typography style={{color:"grey",marginBottom:"10px"}}>
          Find the financing solution that fits your business needs and your wallet.
          <br></br>
           Secure your business loan today.
      
        </Typography>
        <Grid container spacing={2}>
        {[
          {
            name: 'Chary Smith',
            title: 'SEO at Comoti',
            avatar: 'https://assets.maccarianagency.com/avatars/img3.jpg',
          },
          {
            name: 'Clara Bertoletti',
            title: 'Junior Designer',
            avatar: 'https://assets.maccarianagency.com/avatars/img4.jpg',
          },
          {
            name: 'Jhon Anderson',
            title: 'Senior Frontend Developer',
            avatar: 'https://assets.maccarianagency.com/avatars/img5.jpg',
          },
          {
            name: 'Chary Smith',
            title: 'SEO at Comoti',
            avatar: 'https://assets.maccarianagency.com/avatars/img6.jpg',
          },
          {
            name: 'Chary Smith',
            title: 'SEO at Comoti',
            avatar: 'https://assets.maccarianagency.com/avatars/img6.jpg',
          },
          {
            name: 'Chary Smith',
            title: 'SEO at Comoti',
            avatar: 'https://assets.maccarianagency.com/avatars/img6.jpg',
          },
        ].map((item, i) => (
          <Grid
            item
            xs={12}
            sm={6}
            md={4}
            key={i}
            data-aos={'fade-up'}
            data-aos-delay={i * 100}
            data-aos-offset={100}
            data-aos-duration={600}
          >
            <Card
              sx={{
                boxShadow: 0,
                background: 'transparent',
                backgroundImage: 'none',
              }}
            >
              <Box
                component={CardMedia}
                borderRadius={2}
                width={1}
                height={1}
                minHeight={320}
                image={item.avatar}
              />
              <Box
                component={CardContent}
                bgcolor={'transparent'}
                marginTop={-5}
              >
                <Box component={Card}>
                  <CardContent>
                    <ListItemText primary={item.name} secondary={item.title} />
                  </CardContent>
                </Box>
              </Box>
            </Card>
          </Grid>
        ))}
      </Grid>
      <Divider style={{color:"grey"}}/>
      <Typography
                    variant="h4"
                    gutterBottom
                    sx={{
                      align: "center",
                      fontWeight: 400,
                      color: "black",
                    }}
                    style={{marginTop:"10px"}}
                  >
                    Executives
                  </Typography>
                  <Grid container spacing={2}>
        {[
          {
            name: 'Chary Smith',
            title: 'SEO at Comoti',
            avatar: 'https://assets.maccarianagency.com/avatars/img3.jpg',
          },
          {
            name: 'Clara Bertoletti',
            title: 'Junior Designer',
            avatar: 'https://assets.maccarianagency.com/avatars/img4.jpg',
          },
          {
            name: 'Jhon Anderson',
            title: 'Senior Frontend Developer',
            avatar: 'https://assets.maccarianagency.com/avatars/img5.jpg',
          },
          
        ].map((item, i) => (
          <Grid
            item
            xs={12}
            sm={6}
            md={4}
            key={i}
            data-aos={'fade-up'}
            data-aos-delay={i * 100}
            data-aos-offset={100}
            data-aos-duration={600}
          >
            <Card
              sx={{
                boxShadow: 0,
                background: 'transparent',
                backgroundImage: 'none',
              }}
            >
              <Box
                component={CardMedia}
                borderRadius={2}
                width={1}
                height={1}
                minHeight={300}
                image={item.avatar}
              />
              <Box
                component={CardContent}
                bgcolor={'transparent'}
                marginTop={-5}
              >
                <Box component={Card}>
                  <CardContent>
                    <ListItemText primary={item.name} secondary={item.title} />
                  </CardContent>
                </Box>
              </Box>
            </Card>
          </Grid>
        ))}
      </Grid>
      <Divider style={{color:"grey"}}/>
      <Footer2/>
    </Container>
            
            
        </div>
    )
}

export default Leadership
