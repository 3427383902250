import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BrowserRouter, Link, Route, Routes } from "react-router-dom";
import { signout } from "./actions/userActions";
import AdminDash from "./pages/AdminDash";
import DsciCalculator from "./Calculators/DsciCalculator";
import FixandFlipCalc from "./Calculators/FixandFlipCalc";
import ForgotPassword from "./pages/ForgotPassword";
import PartnerSucess from "./pages/PartnerSucess";
import Lendio from "./pages/Lendio";
import UserDashHome from "./pages/UserDashHome";
import UserDashFileUpload from "./pages/UserDashFileUpload";
import BecomePartner from "./pages/BecomePartner";
import Project99Analysis from "./pages/Project99Analysis";
import SinglePropertyAnalysis from "./pages/SinglePropertyAnalysis";
import RentalAnalysis from "./pages/RentalAnalysis";
import MultifamilyAnalysis from "./pages/MultifamilyAnalysis";
import FixandFlipAnalysis from "./pages/FixandFlipAnalysis";
import Navbar from "./components/MainFeed/Navbar";
import Landing from "./pages/Landing";
import TermsofUse from "./pages/TermsofUse";
import Project99 from "./pages/Project99";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import Register from "./pages/RegisterCover";
import ContactPageCover from "./pages/ContactPageCover";
import PaymentFinal from "./pages/PaymentInteraction/PaymentFinal";
import AdminRoute from "./components/AdminRoute";
import LoginCover from "./pages/LoginCover";
import About from "./pages/About";
import Login from "./pages/Login";
import ReferralCode from "./pages/ReferralCode";
import WhyUs from "./pages/WhyUs";
import BlogPage from "./pages/BlogPage";
import StabalizedBridgeAnalysis from "./pages/StabalizedBridgeAnalysis";
import Medical from "./pages/Medical";
import Reviews from "./pages/Reviews";
import PrivateRoute from "./components/PrivateRoute";
import Leadership from "./pages/Leadership";
import FinalHome from "./screens/FinalHome";
import GroundUpAnalysis from "./pages/GroundUpAnalysis";
import Lifeatcapital from "./pages/Lifeatcapital";
import Construction from "./pages/Construction";
import Box from "@mui/material/Box";
import LoanForm from "./pages/LoanForm";
import LoanForm2 from "./pages/LoanForm2";
import Toolbar from "@mui/material/Toolbar";
import Navbar2 from "./screens/Navbar2.js";
import AppBar from "@mui/material/AppBar";
import { Container } from "@mui/material";
import BlogNewsroom from "./pages/blogNews/BlogNewsroom";
import Manufacturing from "./pages/Manufacturing";
import { listProductCategories } from "./actions/productActions";
import SupportScreen from "./screens/SupportScreen";

function App() {
  const cart = useSelector((state) => state.cart);
  const [sidebarIsOpen, setSidebarIsOpen] = useState(false);
  const { cartItems } = cart;
  const userSignin = useSelector((state) => state.userSignin);
  const { userInfo } = userSignin;
  const dispatch = useDispatch();
  const signoutHandler = () => {
    dispatch(signout());
  };

  const productCategoryList = useSelector((state) => state.productCategoryList);
  const {
    loading: loadingCategories,
    error: errorCategories,
    categories,
  } = productCategoryList;
  useEffect(() => {
    dispatch(listProductCategories());
  }, [dispatch]);
  return (
    <BrowserRouter>
      <Navbar style={{ Zindex: "-1" }} />
      <main>
        <Routes>
          <Route path="/becomePartner" element={<BecomePartner />}></Route>
          <Route path="/WhyUs" element={<WhyUs />}></Route>
          <Route path="/privacyPolicy" element={<PrivacyPolicy />}></Route>
          <Route path="/Login" element={<LoginCover />}></Route>
          <Route path="/project99" element={<Project99 />}></Route>
          <Route path="/" element={<Landing />}></Route>
          <Route path="/lendio" element={<Lendio />}></Route>
          <Route path="/contactUs" element={<ContactPageCover />}></Route>
          <Route path="/register" element={<Register />}></Route>
          <Route path="/register/:referralCode" element={<Register />} />
          <Route path="/about" element={<About />}></Route>
          <Route path="/userDash" element={<UserDashHome />}></Route>
          <Route path="/userDashFile" element={<UserDashFileUpload />}></Route>

          <Route path="/partner-success" element={<PartnerSucess />}></Route>
          <Route
            path="/project99-analysis"
            element={<Project99Analysis />}
          ></Route>
          <Route
            path="/single-property-analysis"
            element={<SinglePropertyAnalysis />}
          ></Route>
          <Route path="/rental-analysis" element={<RentalAnalysis />}></Route>
          <Route
            path="/stabalized-bridge-analysis"
            element={<StabalizedBridgeAnalysis />}
          ></Route>

          <Route
            path="/multi-family-analysis"
            element={<MultifamilyAnalysis />}
          ></Route>
          <Route path="/forgot-password" element={<ForgotPassword />}></Route>
          <Route
            path="/loan-form-business-loans"
            element={<LoanForm2 />}
          ></Route>
          <Route path="/fix-and-flip" element={<FixandFlipAnalysis />}></Route>
          <Route path="/DsciCalculator" element={<DsciCalculator />}></Route>
          <Route path="/FixandFlipCalc" element={<FixandFlipCalc />}></Route>
          <Route path="/loan-form-realestate" element={<LoanForm />}></Route>
          <Route path="/paymentFinal" element={<PaymentFinal />}></Route>
          <Route path="/leadership" element={<Leadership />}></Route>
          <Route path="/life-at-capital" element={<Lifeatcapital />}></Route>
          <Route path="/newsRoom" element={<BlogNewsroom />}></Route>
          <Route path="/reviews" element={<Reviews />}></Route>
          <Route path="/blog" element={<BlogPage />}></Route>
          <Route path="/admin" element={<AdminDash />}></Route>
          <Route path="/termsofUse" element={<TermsofUse />}></Route>
          <Route path="/manufacturing" element={<Manufacturing />}></Route>
          <Route path="/referralCode" element={<ReferralCode />}></Route>
          <Route path="/landing" element={<Landing />}></Route>
          <Route path="/medical" element={<Medical />}></Route>
          <Route
            path="/groundUpAnalysis"
            element={<GroundUpAnalysis />}
          ></Route>
          <Route
            path="/heavy-equipment-financing"
            element={<Construction />}
          ></Route>
          <Route
            path="/support"
            element={
              <AdminRoute>
                <SupportScreen />
              </AdminRoute>
            }
          />

          <Route path="/nav" element={<Navbar2 />} />
        </Routes>
      </main>

      {/* <StickyFooter />*/}
    </BrowserRouter>
  );
}

export default App;
