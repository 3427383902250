/* eslint-disable react/no-unescaped-entities */
import React from "react";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import teamImage from "../Images/teamImage.png";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMoneyBill,
  faClockRotateLeft,
  faCashRegister,
  faScrewdriverWrench,
  faBuildingColumns,
  faRocket,
  faFolderMinus,
  faTag,
} from "@fortawesome/free-solid-svg-icons";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Container from "../screens/Container";

const mock = [
  {
    title: "Business Line of Credit",
    icon: <FontAwesomeIcon icon={faMoneyBill} />,
  },
  {
    title: "Short Term Loan",
    icon: <FontAwesomeIcon icon={faClockRotateLeft} />,
  },
  {
    title: "Cash Advance",
    icon: <FontAwesomeIcon icon={faCashRegister} />,
  },
  {
    title: "Equipment Financing",
    icon: <FontAwesomeIcon icon={faScrewdriverWrench} />,
  },
  {
    title: "SBA Loan",
    icon: <FontAwesomeIcon icon={faBuildingColumns} />,
  },
  {
    title: "Startup Business Loans",
    icon: <FontAwesomeIcon icon={faRocket} />,
  },
  {
    title: "Accounts Receivable Financing",
    icon: <FontAwesomeIcon icon={faFolderMinus} />,
  },
  {
    title: "Business Acquisition Loan",
    icon: <FontAwesomeIcon icon={faTag} />,
  },
];

const MeetTheTeam = () => {
  const theme = useTheme();
  return (
    <Box style={{ backgroundColor: "#F6F5F3" }}>
      <Box marginBottom={4} marginTop={2}>
        <Typography
          variant="h4"
          align={"center"}
          data-aos={"fade-up"}
          gutterBottom
          sx={{
            fontWeight: 300,
            color: "black",
          }}
        >
          Meet our leadership team.
        </Typography>
        <Typography
          align={"center"}
          color={"text.secondary"}
          data-aos={"fade-up"}
        >
          It takes talent and vision to revolutionize small business lending.
          Meet the leaders behind the nation’s best small business loan
          marketplace.
        </Typography>
        <Box marginTop={2} display={"flex"} justifyContent={"center"}>
          <Button
            variant="contained"
            color="primary"
            size="large"
            style={{ borderRadius: "30px", backgroundColor: "#498dd6" }}
          >
            Our Team
          </Button>
        </Box>
        <Box marginTop={2} display={"flex"} justifyContent={"center"}>
          <img src={teamImage} style={{ width: "800px" }} />
        </Box>
      </Box>
    </Box>
  );
};

export default MeetTheTeam;
