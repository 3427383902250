import * as React from "react";
import AspectRatio from "@mui/joy/AspectRatio";
import Button from "@mui/joy/Button";
import Card from "@mui/joy/Card";
import CardActions from "@mui/joy/CardActions";
import CardContent from "@mui/joy/CardContent";
import CardOverflow from "@mui/joy/CardOverflow";
import Typography from "@mui/joy/Typography";
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";
import BakeryDiningIcon from "@mui/icons-material/BakeryDining";
import { CardHeader } from "../../node_modules/@mui/material/index";
export default function ResourcesCard() {
  return (
    <Card
      data-resizable
      sx={{
        textAlign: "center",
        alignItems: "center",

        // to make the demo resizable
        overflow: "auto",
        resize: "horizontal",
        "--icon-size": "100px",
      }}
    >
      <CardOverflow variant="outline" color="warning">
        <AspectRatio
          ratio="1"
          sx={{
            m: "auto",
            transform: "translateY(50%)",
            borderRadius: "50%",
            width: "var(--icon-size)",
            boxShadow: "sm",
            bgcolor: "background.surface",
            position: "relative",
          }}
        >
          <div style={{ backgroundColor: "#498dd6" }}>
            <QuestionMarkIcon
              style={{ color: "#ffff" }}
              sx={{ fontSize: "4rem" }}
            />
          </div>
        </AspectRatio>
      </CardOverflow>
      <Typography
        level="h1"
        fontSize="xl"
        sx={{ mt: "calc(var(--icon-size) / 2)" }}
      >
        Manufacturing Accounting: An Introductory Guide
      </Typography>
      <CardContent sx={{ maxWidth: "40ch" }}>Take Me</CardContent>
    </Card>
  );
}
