import React from "react";
import Box from "@mui/material/Box";
import FrontCover from "../components/FrontCover";
import { useTheme } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import { Typography } from "@mui/material";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import Container from "../screens/Container";
import StepLabel from "@mui/material/StepLabel";
import StepContent from "@mui/material/StepContent";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import phone333 from "../Images/phone333.png";
import { Divider } from "../../node_modules/@mui/material/index";
const stepStyle = {
  "& .Mui-active": {
    "&.MuiStepIcon-root": {
      color: "#498dd6",
      fontSize: "3rem",
    },
  },
};
const steps = [
  {
    label: "Apply fast.",
    description: `Fill out our simple application in minutes with no
      impact to your credit.`,
  },
  {
    label: "Get connected.",
    description:
      "An ad group contains one or more ads which target a shared set of keywords.",
  },
  {
    label: "Compare offers.",
    description: `Lendio partners with 75+ lenders to bring you custom
      funding offers for your business.`,
  },
  {
    label: "Get funded.",
    description: `Pick an offer and receive capital for your business in
      as little as 24 hours.`,
  },
];
function TypesofLoans() {
  const theme = useTheme();
  return (
    <div>
      <Box
        display={"flex"}
        flexDirection={{ xs: "column", md: "row" }}
        position={"relative"}
      >
        <Box
          width={1}
          order={{ xs: 2, md: 1 }}
          display={"flex"}
          alignItems={"center"}
        >
          <Container>
            <Box>
              <Typography
                variant="h4"
                align={"center"}
                gutterBottom
                sx={{
                  color: "black",
                  fontWeight: 300,
                  marginTop: theme.spacing(1),
                }}
              >
                Types of Business Loans For the Manufacturing Industry
              </Typography>
            </Box>
          </Container>
        </Box>
      </Box>
      <Container style={{ backgroundColor: "#F5F5F5" }}>
        <Grid container spacing={2}>
          <Grid item sm={6}>
            <Typography
              variant="h5"
              gutterBottom
              sx={{
                color: "black",
                fontWeight: 600,
                marginTop: theme.spacing(1),
              }}
            >
              Asset- or Revenue-Based Financing
            </Typography>
            <Typography
              variant="p"
              gutterBottom
              sx={{
                color: "black",
                fontWeight: 100,
                marginTop: theme.spacing(1),
              }}
            >
              With asset-based financing (also called invoice factoring), a
              funder purchases an invoice from the borrower at a discounted
              rate. The business then pays back the funder as the business
              collects on the invoice. Revenue-based financing gives you an
              advance on expected future revenue rather than an invoice.
            </Typography>
          </Grid>
          <Grid item sm={6} style={{ marginTop: "50px" }}>
            <Grid container spacing={2}>
              <Grid item sm={6}>
                <Typography
                  variant="p"
                  gutterBottom
                  sx={{
                    color: "black",
                    fontWeight: 100,
                    marginTop: theme.spacing(1),
                  }}
                >
                  TERM LENGTH
                </Typography>
                <Divider style={{ backgroundColor: "grey" }} />
                <Typography
                  variant="p"
                  gutterBottom
                  sx={{
                    color: "black",
                    fontWeight: 500,
                    marginTop: theme.spacing(1),
                  }}
                >
                  1-15 months
                </Typography>
              </Grid>
              <Grid item sm={6}>
                <Typography
                  variant="p"
                  gutterBottom
                  sx={{
                    color: "black",
                    fontWeight: 100,
                    marginTop: theme.spacing(1),
                  }}
                >
                  MAX LOAN AMOUNT
                </Typography>
                <Divider style={{ backgroundColor: "grey" }} />
                <Typography
                  variant="p"
                  gutterBottom
                  sx={{
                    color: "black",
                    fontWeight: 500,
                    marginTop: theme.spacing(1),
                  }}
                >
                  $1 million (RBF); $50 million (factoring)
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
      {/* Second*/}
      <Container style={{ backgroundColor: "#F5F5F5", marginTop: "20px" }}>
        <Grid container spacing={2}>
          <Grid item sm={6}>
            <Typography
              variant="h5"
              gutterBottom
              sx={{
                color: "black",
                fontWeight: 600,
                marginTop: theme.spacing(1),
              }}
            >
              Debt Financing
            </Typography>
            <Typography
              variant="p"
              gutterBottom
              sx={{
                color: "black",
                fontWeight: 100,
                marginTop: theme.spacing(1),
              }}
            >
              Debt financing is more of a “traditional” business loan, as
              borrowers receive the loan amount in a lump sum and make fixed
              monthly payments until the balance is repaid in full. Small
              business owners can choose either a term loan or an SBA loan. SBA
              loans typically have lower interest rates and higher borrowing
              limits, but can be more difficult to qualify for.
            </Typography>
          </Grid>
          <Grid item sm={6} style={{ marginTop: "50px" }}>
            <Grid container spacing={2}>
              <Grid item sm={6}>
                <Typography
                  variant="p"
                  gutterBottom
                  sx={{
                    color: "black",
                    fontWeight: 100,
                    marginTop: theme.spacing(1),
                  }}
                >
                  TERM LENGTH
                </Typography>
                <Divider style={{ backgroundColor: "grey" }} />
                <Typography
                  variant="p"
                  gutterBottom
                  sx={{
                    color: "black",
                    fontWeight: 500,
                    marginTop: theme.spacing(1),
                  }}
                >
                  6 months-25 years
                </Typography>
              </Grid>
              <Grid item sm={6}>
                <Typography
                  variant="p"
                  gutterBottom
                  sx={{
                    color: "black",
                    fontWeight: 100,
                    marginTop: theme.spacing(1),
                  }}
                >
                  MAX LOAN AMOUNT
                </Typography>
                <Divider style={{ backgroundColor: "grey" }} />
                <Typography
                  variant="p"
                  gutterBottom
                  sx={{
                    color: "black",
                    fontWeight: 500,
                    marginTop: theme.spacing(1),
                  }}
                >
                  $5 million
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
      <Container style={{ backgroundColor: "#F5F5F5", marginTop: "20px" }}>
        <Grid container spacing={2}>
          <Grid item sm={6}>
            <Typography
              variant="h5"
              gutterBottom
              sx={{
                color: "black",
                fontWeight: 600,
                marginTop: theme.spacing(1),
              }}
            >
              Line of Credit
            </Typography>
            <Typography
              variant="p"
              gutterBottom
              sx={{
                color: "black",
                fontWeight: 100,
                marginTop: theme.spacing(1),
              }}
            >
              A business line of credit is very similar to a credit card, but
              there is a set draw period, the interest rates are lower, and the
              credit limits are much higher. Unlike other business loans, a line
              of credit can be used for anything and is useful for businesses in
              need of more working capital.
            </Typography>
          </Grid>
          <Grid item sm={6} style={{ marginTop: "50px" }}>
            <Grid container spacing={2}>
              <Grid item sm={6}>
                <Typography
                  variant="p"
                  gutterBottom
                  sx={{
                    color: "black",
                    fontWeight: 100,
                    marginTop: theme.spacing(1),
                  }}
                >
                  TERM LENGTH
                </Typography>
                <Divider style={{ backgroundColor: "grey" }} />
                <Typography
                  variant="p"
                  gutterBottom
                  sx={{
                    color: "black",
                    fontWeight: 500,
                    marginTop: theme.spacing(1),
                  }}
                >
                  6-24 months
                </Typography>
              </Grid>
              <Grid item sm={6}>
                <Typography
                  variant="p"
                  gutterBottom
                  sx={{
                    color: "black",
                    fontWeight: 100,
                    marginTop: theme.spacing(1),
                  }}
                >
                  MAX LOAN AMOUNT
                </Typography>
                <Divider style={{ backgroundColor: "grey" }} />
                <Typography
                  variant="p"
                  gutterBottom
                  sx={{
                    color: "black",
                    fontWeight: 500,
                    marginTop: theme.spacing(1),
                  }}
                >
                  Up to $250,000
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}

export default TypesofLoans;
