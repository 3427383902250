import React from "react";
import Hero from "../components/Hero";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Container from "../screens/Container";
import Story from "../components/Story";
import PopularNews from "../components/PopularNews";
import Categories from "../components/Categories";
import MeetTheTeam from "../components/MeetTheTeam";
import MeetTheTeamAbout from "../components/MeetTheTeamAbout";
import Footer2 from "../components/Footer2";
import PromoNumbers from "../components/PromoNumbers";
import { Divider } from "../../node_modules/@mui/material/index";
function About() {
  return (
    <div>
      <Box
        style={{ backgroundColor: "#F6F5F3" }}
        position={"relative"}
        zIndex={3}
      >
        <PromoNumbers />
      </Box>
      <Container>
        <Story style={{ marginTop: "10px" }} />
      </Container>
      <Box style={{ backgroundColor: "#F6F5F3" }}>
        <Container>
          <Categories />
        </Container>
      </Box>

      <Divider style={{ color: "grey" }} />
      <Footer2 />
    </div>
  );
}

export default About;
