import React from 'react'
import Container from "../screens/Container";
function Lifeatcapital() {
    return (
        <div>
            hi
        </div>
    )
}

export default Lifeatcapital
