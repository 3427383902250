import React from "react";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Grid from "@mui/material/Grid";
import Avatar from "@mui/material/Avatar";

const mock = [
  {
    image:
      "https://images.pexels.com/photos/3182781/pexels-photo-3182781.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
    description:
      "Sed ut perspiciatis unde omnis iste natus error sit voluptatem",
    title: "Eiusmod tempor incididunt",
    author: {
      name: "Clara Bertoletti",
      avatar: "https://assets.maccarianagency.com/avatars/img1.jpg",
    },
    date: "10 Sep",
  },
  {
    image:
      "https://images.pexels.com/photos/4623506/pexels-photo-4623506.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
    description: "At vero eos et accusamus et iusto odio dignissimos ducimus",
    title: "Sed ut perspiciatis",
    author: {
      name: "Jhon Anderson",
      avatar: "https://assets.maccarianagency.com/avatars/img2.jpg",
    },
    date: "02 Aug",
  },
  {
    image:
      "https://images.pexels.com/photos/7163375/pexels-photo-7163375.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
    description:
      "Qui blanditiis praesentium voluptatum deleniti atque corrupti",
    title: "Unde omnis iste natus",
    author: {
      name: "Chary Smith",
      avatar: "https://assets.maccarianagency.com/avatars/img3.jpg",
    },
    date: "05 Mar",
  },
];

const FeaturedArticles = () => {
  const theme = useTheme();
  return (
    <Box>
      <Box
        display={"flex"}
        justifyContent={"space-between"}
        alignItems={{ xs: "flex-start", sm: "center" }}
        flexDirection={{ xs: "column", sm: "row" }}
        marginBottom={4}
      >
        <Box>
          <Typography
            fontWeight={700}
            variant={"h4"}
            style={{ color: "black" }}
            gutterBottom
          >
            Featured stories
          </Typography>
          <Typography color={"text.secondary"}>
            Here’s what we’ve been up to recently.
          </Typography>
        </Box>
        <Box display="flex" marginTop={{ xs: 2, md: 0 }}>
          <Box
            component={Button}
            variant="outlined"
            style={{
              color: "#498dd6",
              borderColor: "#498dd6",
              borderRadius: "30px",
            }}
            size="large"
            marginLeft={2}
          >
            View all
          </Box>
        </Box>
      </Box>
      <Grid container spacing={4}>
        {mock.map((item, i) => (
          <Grid item xs={12} md={4} key={i}>
            <Box
              component={"a"}
              href={""}
              display={"block"}
              width={1}
              height={1}
              sx={{
                textDecoration: "none",
                transition: "all .2s ease-in-out",
                "&:hover": {
                  transform: `translateY(-${theme.spacing(1 / 2)})`,
                },
              }}
            >
              <Box
                component={Card}
                width={1}
                height={1}
                boxShadow={0}
                sx={{ bgcolor: "transparent", backgroundImage: "none" }}
              >
                <CardMedia
                  image={item.image}
                  title={item.title}
                  sx={{
                    height: { xs: 300, md: 360 },
                    position: "relative",
                    filter:
                      theme.palette.mode === "dark"
                        ? "brightness(0.7)"
                        : "none",
                  }}
                />
                <Box
                  width={"90%"}
                  margin={"0 auto"}
                  display={"flex"}
                  flexDirection={"column"}
                  boxShadow={3}
                  borderRadius={2}
                  bgcolor={"background.paper"}
                  position={"relative"}
                  zIndex={3}
                  sx={{ transform: "translateY(-30px)" }}
                >
                  <Box component={CardContent} position={"relative"}>
                    <Typography variant={"h6"} gutterBottom>
                      {item.title}
                    </Typography>
                    <Typography color="text.secondary">
                      {item.description}
                    </Typography>
                  </Box>
                  <Box flexGrow={1} />
                  <Box padding={2} display={"flex"} flexDirection={"column"}>
                    <Box marginBottom={2}>
                      <Divider />
                    </Box>
                    <Box
                      display={"flex"}
                      justifyContent={"space-between"}
                      alignItems={"center"}
                    >
                      <Box display={"flex"} alignItems={"center"}>
                        <Avatar
                          src={item.author.avatar}
                          sx={{ marginRight: 1 }}
                        />
                        <Typography color={"text.secondary"}>
                          {item.author.name}
                        </Typography>
                      </Box>
                      <Typography color={"text.secondary"}>
                        {item.date}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default FeaturedArticles;
