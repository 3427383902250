import React, { useState } from "react";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Container from "../../screens/Container";
import Typography from "@mui/material/Typography";
import CheckoutSteps from "../CheckoutSteps";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import dayjs from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import FormLabel from "@mui/material/FormLabel";
import Slider from "@mui/material/Slider";

function BorrowerStep4({ formData, setFormData, fieldErrors }) {
  const [selectedOption, setSelectedOption] = useState("");

  const handleOptionChange = (fieldName, value) => {
    setFormData({
      ...formData,
      [fieldName]: value, // Update the specified field in formData with the selected value
    });
  };
  return (
    <div>
      <CheckoutSteps step1></CheckoutSteps>

      <Container>
        <Typography variant="h4" color="black" gutterBottom>
          Borrower information
        </Typography>
        <label
          style={{
            fontSize: 15,
            fontWeight: 300,
            color: fieldErrors.bestTerms ? "red" : "grey",
          }}
        >
          Is the transaction arm’s length where buyer and seller are trying to
          get the best terms for their respective sides? *
        </label>

        <RadioGroup
          row
          aria-labelledby="demo-row-radio-buttons-group-label"
          name="row-radio-buttons-group"
        >
          <FormControlLabel
            style={{ color: "black" }}
            value="Yes" // Set the value to "Yes" when selected
            checked={formData.bestTerms === "Yes"} // Check if it's "Yes" in formData
            onChange={() => handleOptionChange("bestTerms", "Yes")}
            error={fieldErrors.bestTerms}
            helperText={<span>{fieldErrors.bestTerms}</span>}
            control={<Radio />}
            label="Yes"
          />
          <FormControlLabel
            style={{ color: "black" }}
            value="No" // Set the value to "No" when selected
            checked={formData.bestTerms === "No"} // Check if it's "No" in formData
            onChange={() => handleOptionChange("bestTerms", "No")}
            control={<Radio />}
            label="No"
          />
        </RadioGroup>
        <div style={{ marginTop: 20 }}>
          {formData.bestTerms === "Yes" && (
            <Grid container spacing={2}>
              <TextField
                style={{
                  width: "500px",
                  marginTop: 10,
                  backgroundColor: "white",
                }}
                value={formData.armsLengthDescription || ""}
                size="large"
                InputLabelProps={{ style: { fontSize: 15, fontWeight: 100 } }}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    armsLengthDescription: e.target.value,
                  })
                }
                label="Please describe"
                variant="outlined"
              />
            </Grid>
          )}
        </div>
      </Container>
    </div>
  );
}

export default BorrowerStep4;
