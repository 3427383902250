import React from "react";
import Box from "@mui/material/Box";
import FrontCover from "../components/FrontCover";
import { useTheme } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import { Typography } from "@mui/material";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import Container from "../screens/Container";
import StepLabel from "@mui/material/StepLabel";
import StepContent from "@mui/material/StepContent";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import phone333 from "../Images/phone333.png";
import { Divider } from "../../node_modules/@mui/material/index";
const stepStyle = {
  "& .Mui-active": {
    "&.MuiStepIcon-root": {
      color: "#498dd6",
      fontSize: "3rem",
    },
  },
};
const steps = [
  {
    label: "Apply fast.",
    description: `Fill out our simple application in minutes with no
      impact to your credit.`,
  },
  {
    label: "Get connected.",
    description:
      "An ad group contains one or more ads which target a shared set of keywords.",
  },
  {
    label: "Compare offers.",
    description: `Lendio partners with 75+ lenders to bring you custom
      funding offers for your business.`,
  },
  {
    label: "Get funded.",
    description: `Pick an offer and receive capital for your business in
      as little as 24 hours.`,
  },
];
function TypesofloansMedical() {
  const theme = useTheme();
  return (
    <div>
      <Box
        display={"flex"}
        flexDirection={{ xs: "column", md: "row" }}
        position={"relative"}
      >
        <Box
          width={1}
          order={{ xs: 2, md: 1 }}
          display={"flex"}
          alignItems={"center"}
        >
          <Container>
            <Box>
              <Typography
                variant="h4"
                align={"center"}
                gutterBottom
                sx={{
                  color: "black",
                  fontWeight: 300,
                  marginTop: theme.spacing(1),
                }}
              >
                Types of Business Loans For Medical Practices
              </Typography>
            </Box>
          </Container>
        </Box>
      </Box>
      <Container style={{ backgroundColor: "#F5F5F5" }}>
        <Grid container spacing={2}>
          <Grid item sm={6}>
            <Typography
              variant="h5"
              gutterBottom
              sx={{
                color: "black",
                fontWeight: 600,
                marginTop: theme.spacing(1),
              }}
            >
              Invoice Factoring And Revenue-Based Financing
            </Typography>
            <Typography
              variant="p"
              gutterBottom
              sx={{
                color: "black",
                fontWeight: 100,
                marginTop: theme.spacing(1),
              }}
            >
              Invoice factoring and revenue-based financing both use future
              revenue as collateral for a loan. Certain lenders we work with
              specialize in factoring specific to the medical industry, based on
              your insurance receivables.
            </Typography>
          </Grid>
          <Grid item sm={6} style={{ marginTop: "50px" }}>
            <Grid container spacing={2}>
              <Grid item sm={6}>
                <Typography
                  variant="p"
                  gutterBottom
                  sx={{
                    color: "black",
                    fontWeight: 100,
                    marginTop: theme.spacing(1),
                  }}
                >
                  TERM LENGTH
                </Typography>
                <Divider style={{ backgroundColor: "grey" }} />
                <Typography
                  variant="p"
                  gutterBottom
                  sx={{
                    color: "black",
                    fontWeight: 500,
                    marginTop: theme.spacing(1),
                  }}
                >
                  1-15 months
                </Typography>
              </Grid>
              <Grid item sm={6}>
                <Typography
                  variant="p"
                  gutterBottom
                  sx={{
                    color: "black",
                    fontWeight: 100,
                    marginTop: theme.spacing(1),
                  }}
                >
                  MAX LOAN AMOUNT
                </Typography>
                <Divider style={{ backgroundColor: "grey" }} />
                <Typography
                  variant="p"
                  gutterBottom
                  sx={{
                    color: "black",
                    fontWeight: 500,
                    marginTop: theme.spacing(1),
                  }}
                >
                  $50 million (factoring); $1 million (RBF)
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
      {/* Second*/}
      <Container style={{ backgroundColor: "#F5F5F5", marginTop: "20px" }}>
        <Grid container spacing={2}>
          <Grid item sm={6}>
            <Typography
              variant="h5"
              gutterBottom
              sx={{
                color: "black",
                fontWeight: 600,
                marginTop: theme.spacing(1),
              }}
            >
              Debt Financing
            </Typography>
            <Typography
              variant="p"
              gutterBottom
              sx={{
                color: "black",
                fontWeight: 100,
                marginTop: theme.spacing(1),
              }}
            >
              Debt financing is when you borrow money for your medical practice
              for a set term with a set rate and monthly payment. Two examples
              of debt financing include term loans, which offer a lump sum of
              money up front, and SBA loans, which are partially guaranteed by
              the U.S. Small Business Administration (SBA).
            </Typography>
          </Grid>
          <Grid item sm={6} style={{ marginTop: "50px" }}>
            <Grid container spacing={2}>
              <Grid item sm={6}>
                <Typography
                  variant="p"
                  gutterBottom
                  sx={{
                    color: "black",
                    fontWeight: 100,
                    marginTop: theme.spacing(1),
                  }}
                >
                  TERM LENGTH
                </Typography>
                <Divider style={{ backgroundColor: "grey" }} />
                <Typography
                  variant="p"
                  gutterBottom
                  sx={{
                    color: "black",
                    fontWeight: 500,
                    marginTop: theme.spacing(1),
                  }}
                >
                  6 months-25 years
                </Typography>
              </Grid>
              <Grid item sm={6}>
                <Typography
                  variant="p"
                  gutterBottom
                  sx={{
                    color: "black",
                    fontWeight: 100,
                    marginTop: theme.spacing(1),
                  }}
                >
                  MAX LOAN AMOUNT
                </Typography>
                <Divider style={{ backgroundColor: "grey" }} />
                <Typography
                  variant="p"
                  gutterBottom
                  sx={{
                    color: "black",
                    fontWeight: 500,
                    marginTop: theme.spacing(1),
                  }}
                >
                  $5 million
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
      <Container style={{ backgroundColor: "#F5F5F5", marginTop: "20px" }}>
        <Grid container spacing={2}>
          <Grid item sm={6}>
            <Typography
              variant="h5"
              gutterBottom
              sx={{
                color: "black",
                fontWeight: 600,
                marginTop: theme.spacing(1),
              }}
            >
              Line of Credit
            </Typography>
            <Typography
              variant="p"
              gutterBottom
              sx={{
                color: "black",
                fontWeight: 100,
                marginTop: theme.spacing(1),
              }}
            >
              A line of credit offers a flexible way to finance your medical
              practice. Upon approval, you can withdraw funds as you need to, up
              to a set credit limit. You’ll pay interest only on the amount you
              borrow, rather than the entire amount for which you’re approved.
            </Typography>
          </Grid>
          <Grid item sm={6} style={{ marginTop: "50px" }}>
            <Grid container spacing={2}>
              <Grid item sm={6}>
                <Typography
                  variant="p"
                  gutterBottom
                  sx={{
                    color: "black",
                    fontWeight: 100,
                    marginTop: theme.spacing(1),
                  }}
                >
                  TERM LENGTH
                </Typography>
                <Divider style={{ backgroundColor: "grey" }} />
                <Typography
                  variant="p"
                  gutterBottom
                  sx={{
                    color: "black",
                    fontWeight: 500,
                    marginTop: theme.spacing(1),
                  }}
                >
                  6-24 months
                </Typography>
              </Grid>
              <Grid item sm={6}>
                <Typography
                  variant="p"
                  gutterBottom
                  sx={{
                    color: "black",
                    fontWeight: 100,
                    marginTop: theme.spacing(1),
                  }}
                >
                  MAX LOAN AMOUNT
                </Typography>
                <Divider style={{ backgroundColor: "grey" }} />
                <Typography
                  variant="p"
                  gutterBottom
                  sx={{
                    color: "black",
                    fontWeight: 500,
                    marginTop: theme.spacing(1),
                  }}
                >
                  Up to $250,000
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
      <Container style={{ backgroundColor: "#F5F5F5", marginTop: "20px" }}>
        <Grid container spacing={2}>
          <Grid item sm={6}>
            <Typography
              variant="h5"
              gutterBottom
              sx={{
                color: "black",
                fontWeight: 600,
                marginTop: theme.spacing(1),
              }}
            >
              Line of Credit
            </Typography>
            <Typography
              variant="p"
              gutterBottom
              sx={{
                color: "black",
                fontWeight: 100,
                marginTop: theme.spacing(1),
              }}
            >
              A line of credit offers a flexible way to finance your medical
              practice. Upon approval, you can withdraw funds as you need to, up
              to a set credit limit. You’ll pay interest only on the amount you
              borrow, rather than the entire amount for which you’re approved.
            </Typography>
          </Grid>
          <Grid item sm={6} style={{ marginTop: "50px" }}>
            <Grid container spacing={2}>
              <Grid item sm={6}>
                <Typography
                  variant="p"
                  gutterBottom
                  sx={{
                    color: "black",
                    fontWeight: 100,
                    marginTop: theme.spacing(1),
                  }}
                >
                  TERM LENGTH
                </Typography>
                <Divider style={{ backgroundColor: "grey" }} />
                <Typography
                  variant="p"
                  gutterBottom
                  sx={{
                    color: "black",
                    fontWeight: 500,
                    marginTop: theme.spacing(1),
                  }}
                >
                  6-24 months
                </Typography>
              </Grid>
              <Grid item sm={6}>
                <Typography
                  variant="p"
                  gutterBottom
                  sx={{
                    color: "black",
                    fontWeight: 100,
                    marginTop: theme.spacing(1),
                  }}
                >
                  MAX LOAN AMOUNT
                </Typography>
                <Divider style={{ backgroundColor: "grey" }} />
                <Typography
                  variant="p"
                  gutterBottom
                  sx={{
                    color: "black",
                    fontWeight: 500,
                    marginTop: theme.spacing(1),
                  }}
                >
                  Up to $250,000
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}

export default TypesofloansMedical;
