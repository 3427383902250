import React, { useState, useEffect } from "react";
import Container from "../screens/Container";
import PropTypes from "prop-types";
import ButtonGroup from "@mui/material/ButtonGroup";
import {
  Button,
  Modal,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Grid,
  Typography,
  Divider,
} from "@mui/material";
import { ToastContainer, toast } from "react-toastify";
import AntDesignSideBar from "../components/AntDesignSideBar";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import Box from "@mui/material/Box";
function ReferralCode() {
  const drawerWidth = 240;
  const [data, setData] = useState([]);
  const [open, setOpen] = useState(false);
  const [newEntry, setNewEntry] = useState({
    firstName: "",
    lastName: "",
    youtubeLink: "",
    referralCode: "",
  });

  const fetchData = async () => {
    try {
      const response = await axios.get(
        "https://3.16.76.222:8080//api/referrals/getAllReferrals"
      ); // Replace with your API endpoint
      setData(response.data);
    } catch (error) {
      toast.error(error);
      console.error("Error fetching data:", error);
    }
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 500,
    bgcolor: "background.paper",

    boxShadow: 24,
    p: 4,
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleDelete = (referralId) => {
    // Send a DELETE request to the backend to delete the referral by ID
    axios
      .delete(
        `https://3.16.76.222:8080//api/referrals/deleteReferral/${referralId}`
      )
      .then((response) => {
        // Handle successful deletion (e.g., refresh the table)
        fetchData();
      })
      .catch((error) => {
        console.error("Error deleting referral:", error);
      });
  };

  const handleApprove = (referralId) => {
    // Send a DELETE request to the backend to delete the referral by ID
    axios
      .post(
        `https://3.16.76.222:8080//api/referrals/approveReferral/${referralId}`
      )
      .then((response) => {
        // Handle successful deletion (e.g., refresh the table)
        fetchData();
      })
      .catch((error) => {
        console.error("Error deleting referral:", error);
      });
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setNewEntry({ ...newEntry, [name]: value });
  };

  const handleSubmit = async () => {
    try {
      await axios.post(
        "https://3.16.76.222:8080//api/referrals/addReferral",
        newEntry
      ); // Replace with your API endpoint
      fetchData();
      setNewEntry({
        firstName: "",
        lastName: "",
        youtubeLink: "",
        referralCode: "",
      });
      handleClose();
    } catch (error) {
      toast.error(error);
      console.error("Error submitting data:", error);
    }
  };

  return (
    <div>
      <Box sx={{ display: "flex" }}>
        <Box
          component="nav"
          sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
          aria-label="mailbox folders"
        >
          {/* The implementation can be swapped with js to avoid SEO duplication of links. */}

          <AntDesignSideBar />
        </Box>
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            p: 3,
            width: { sm: `calc(100% - ${drawerWidth}px)` },
          }}
        >
          <Typography variant="h4" style={{ color: "grey" }}>
            Manage Referral Code
          </Typography>
          <Divider />
          <Container>
            {/*
         
            <Button
              style={{
                backgroundColor: "#498dd6",
                color: "white",
                marginBottom: 10,
              }}
              onClick={handleOpen}
            >
              Add Referral Code
            </Button>
               */}
            <Modal open={open} onClose={handleClose}>
              <Box sx={style}>
                <h2 style={{ color: "black" }}>Add Referral Code</h2>
                <Grid Container spacing={2}>
                  <Grid item sm={6} style={{ marginBottom: 10 }}>
                    <TextField
                      fullwidth
                      name="firstName"
                      label="First Name"
                      value={newEntry.firstName}
                      onChange={handleInputChange}
                    />
                  </Grid>
                  <Grid item sm={6} style={{ marginBottom: 10 }}>
                    <TextField
                      name="lastName"
                      label="Last Name"
                      value={newEntry.lastName}
                      onChange={handleInputChange}
                    />
                  </Grid>
                  <Grid item sm={6} style={{ marginBottom: 10 }}>
                    <TextField
                      name="youtubeLink"
                      label="Youtube Link"
                      value={newEntry.youtubeLink}
                      onChange={handleInputChange}
                    />
                  </Grid>
                  <Grid item sm={6} style={{ marginBottom: 10 }}>
                    <TextField
                      name="referralCode"
                      label="Referral Code"
                      value={newEntry.referralCode}
                      onChange={handleInputChange}
                    />
                  </Grid>
                </Grid>
                <Button
                  style={{
                    backgroundColor: "#498dd6",
                    color: "white",
                    marginBottom: 10,
                  }}
                  variant="contained"
                  onClick={handleSubmit}
                >
                  Submit
                </Button>
              </Box>
            </Modal>
            <Paper>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>FirstName</TableCell>
                      <TableCell>LastName</TableCell>
                      <TableCell>Home Address</TableCell>
                      <TableCell>City</TableCell>
                      <TableCell>Zip Code</TableCell>
                      <TableCell>State</TableCell>
                      <TableCell>Phone</TableCell>
                      <TableCell>Email</TableCell>
                      <TableCell>Youtube Link</TableCell>
                      <TableCell>Referral Code</TableCell>
                      <TableCell>isApproved</TableCell>
                      <TableCell>Action</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {data.map((row) => (
                      <TableRow key={row._id}>
                        <TableCell>{row.firstName}</TableCell>
                        <TableCell>{row.lastName}</TableCell>
                        <TableCell>{row.homeAddress}</TableCell>
                        <TableCell>{row.city}</TableCell>
                        <TableCell>{row.zipCode}</TableCell>
                        <TableCell>{row.state}</TableCell>
                        <TableCell>{row.phone}</TableCell>
                        <TableCell>{row.email}</TableCell>
                        <TableCell>{row.youtubeLink}</TableCell>
                        <TableCell>{row.referralCode}</TableCell>
                        <TableCell>
                          {row.isApproved ? "Approved" : "Not Approved"}
                        </TableCell>
                        <TableCell>
                          <div style={{ display: "flex", gap: "8px" }}>
                            {row.isApproved ? null : (
                              <Button
                                variant="contained"
                                color="success"
                                onClick={() => handleApprove(row._id)}
                              >
                                Approve
                              </Button>
                            )}
                            <Button
                              variant="contained"
                              color="error"
                              onClick={() => handleDelete(row._id)}
                            >
                              Delete
                            </Button>
                          </div>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
          </Container>
        </Box>
      </Box>
    </div>
  );
}
ReferralCode.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

export default ReferralCode;
