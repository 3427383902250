import React from "react";
import HomeHeroReview from "../components/HomeHeroReview";
import ReviewStars from '../components/ReviewStars'
import ReviewsWithSimpleBoxes from '../components/ReviewsWithSimpleBoxes';
import Industry from '../components/Industry';
import Footer2 from "../components/Footer2";
export default function Reviews() {
    return (
        <div>
          <HomeHeroReview/>
          <ReviewStars/>
          <ReviewsWithSimpleBoxes/>
          <Industry style={{marginTop:"10px"}}/>
          <Footer2/>
        </div>
    )
}
